export const FIELD_KEYS = {
    TOTAL_ACTIVE_JOBS: "totalActiveJobs",
    TOTAL_COMPLETED_JOBS: "totalCompletedJobs",
    TOTAL_CANCELLED_JOBS: "totalCancelledJobs",
    NORMAL_JOBS: "normalJobs",
    QUICK_HIRES: "quickHires",
    DIRECT_HIRES: "directHires",
    TOTAL_REGISTERED_USERS: "totalRegisteredUsers",
    TOTAL_PROVIDERS: "totalProviders",
    TOTAL_SEEKERS: "totalSeekers",
    // TOTAL_GUEST_USERS: "totalGuestUsers",
    GUEST_PROVIDERS: "guestProviders",
    GUEST_SEEKERS: "guestSeekers"
};

export const FIELD_MAPPING = {
    [FIELD_KEYS.TOTAL_ACTIVE_JOBS]: "Total Active Jobs",
    [FIELD_KEYS.TOTAL_COMPLETED_JOBS]: "Total Completed Jobs",
    [FIELD_KEYS.TOTAL_CANCELLED_JOBS]: "Total Cancelled Jobs",
    [FIELD_KEYS.NORMAL_JOBS]: "Normal Jobs",
    [FIELD_KEYS.QUICK_HIRES]: "Quick Hires",
    [FIELD_KEYS.DIRECT_HIRES]: "Direct Hires",
    [FIELD_KEYS.TOTAL_REGISTERED_USERS]: "Total Registered Users",
    [FIELD_KEYS.TOTAL_PROVIDERS]: "Total Service Providers",
    [FIELD_KEYS.TOTAL_SEEKERS]: "Total Service Seekers",
    // [FIELD_KEYS.TOTAL_GUEST_USERS]: "Total Guest Users",
    [FIELD_KEYS.GUEST_PROVIDERS]: "Guest Providers",
    [FIELD_KEYS.GUEST_SEEKERS]: "Guest Seekers"
};

export const mapUserDataToCards = (userData, jobData) => {
    return [
        {
            title: "Total Users",
            item: {
                // totalGuestUsers: jobData?.totalGuestUsers || 0,
                totalRegisteredUsers: jobData?.totalUsers || 0,
                totalProviders: jobData?.totalProviderUsers || 0,
                totalSeekers: jobData?.totalSeekerUsers || 0
            }
        },
        {
            title: "Active Jobs",
            item: {
                totalActiveJobs:
                    userData?.activeJobs?.totalNormalJobs +
                        userData?.activeJobs?.totalQuickJobs +
                        userData?.activeJobs?.totalDirectJobs || 0,
                totalNormalJobs: userData?.activeJobs?.totalNormalJobs || 0,
                totalQuickJobs: userData?.activeJobs?.totalQuickJobs || 0,
                totalDirectJobs: userData?.activeJobs?.totalDirectJobs || 0
            }
        },
        {
            title: "Completed Jobs",
            item: {
                totalCompletedJobs:
                    userData?.completedJobs?.totalNormalJobs +
                        userData?.completedJobs?.totalQuickJobs +
                        userData?.completedJobs?.totalDirectJobs || 0,
                totalNormalJobs: userData?.completedJobs?.totalNormalJobs || 0,
                totalQuickJobs: userData?.completedJobs?.totalQuickJobs || 0,
                totalDirectJobs: userData?.completedJobs?.totalDirectJobs || 0
            }
        },
        {
            title: "Cancelled Jobs",
            item: {
                totalCancelledJobs:
                    userData?.cancelledJobs?.totalNormalJobs +
                        userData?.cancelledJobs?.totalQuickJobs +
                        userData?.cancelledJobs?.totalDirectJobs || 0,
                totalNormalJobs: userData?.cancelledJobs?.totalNormalJobs || 0,
                totalQuickJobs: userData?.cancelledJobs?.totalQuickJobs || 0,
                totalDirectJobs: userData?.cancelledJobs?.totalDirectJobs || 0
            }
        }
    ];
};
