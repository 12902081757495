export default {
    LOGIN: "Login",
    FORGET_PASSWORD: "Forget Password",
    OTP_VERIFICATION: "OTP Verification",
    LOGOUT: "Logout",
    RESET_PASSWORD: "Reset Password",
    DASHBOARD: "Dashboard",
    USER_MANAGEMENT: "User Management",
    PROVIDER_MANAGEMENT: "Provider Management",
    SEEKER_MANAGEMENT: "Seeker Management",
    CATEGORY_MANAGEMENT: "Category Management",
    JOB_LISTING: "Job Listing",
    ACCOUNT: "Account"
};
