export const renderStars = (rating) => {
    const stars = [];
    const maxStars = 5;
    let fullStars = Math.floor(rating); // Number of full stars
    const halfStar = rating - fullStars >= 0.5; // Check if there's a half-star

    for (let i = 1; i <= maxStars; i++) {
        if (i <= fullStars) {
            stars.push(<i key={i} className="bx bxs-star" style={{ color: "#ffd700" }}></i>); // Full star
        } else if (halfStar && i === fullStars + 1) {
            stars.push(<i key={i} className="bx bxs-star-half" style={{ color: "#ffd700" }}></i>); // Half star
        } else {
            stars.push(<i key={i} className="bx bx-star" style={{ color: "#ffd700" }}></i>); // Empty star
        }
    }
    return stars;
};
