import React from "react";
import { Container } from "reactstrap";

import "./Styles.scss";
import APP_CONFIG from "../../APP_CONFIG";

const AuthWrapper = ({ className = "", children }) => {
    return (
        <div
            className={`${className} d-flex align-items-center justify-center min-vh-100 min-vw-100`}
        >
            <Container className="w-50">
                <div className="auth-wrap-right-content w-full">{children}</div>
            </Container>
        </div>
    );
};

export default AuthWrapper;
