import React, { useState } from "react";
import { Helmet } from "react-helmet";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { withRouter, useHistory, useParams, Redirect, Link } from "react-router-dom";
import { useFormik } from "formik";
import { Label } from "reactstrap";

import AuthWrapper from "./AuthWrapper";
import { PasswordInput } from "../../components/Input";
import { CustomButton } from "../../components/CustomButton";
import { APP_ROUTES, getParamsAttachedRoute } from "../../helpers/routeHelpers";
import { ResetPassword } from "../../api/api.service";
import { PageTitles } from "../../constants";
import Headings from "./Headings";
import { CustomModal } from "../../components/CustomModal";
import successImg from "../../assets/images/app/common/modal-success-img.svg";
import { initialValues, resetPassFormValidationSchema } from "./FormValidations";
import { SUCCESS, showToast } from "../../utils/toastUtils";

const ResetPasswordView = ({}) => {
    const history = useHistory();
    const { tokenUUId } = useParams();
    let isAuthenticated = useSelector((state) => state.userAuth.isAuthenticated);

    const [isLoading, setIsLoading] = useState(false);
    const [showResetSuccessModal, setShowResetSuccessModal] = useState(false);

    const formik = useFormik({
        initialValues: {
            newPassword: "",
            confirmPassword: ""
        },
        validationSchema: Yup.object({
            newPassword: Yup.string()
                .required("Enter New Password")
                .min(6, "Password should be minimum 6 characters long")
                .max(25, "Password should be between 6 to 25 characters")
                .matches(
                    /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{6,25}$/,
                    "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
                ),
            confirmPassword: Yup.string()
                .oneOf([Yup.ref("newPassword")], "Please make sure your passwords match")
                .required("Confirm Password is required")
        }),
        onSubmit: (values) => {
            handleSubmit(values);
        }
    });

    const handleSubmit = async (values) => {
        let { confirmPassword } = values;

        try {
            setIsLoading(true);
            let body = {
                verificationToken: tokenUUId,
                password: confirmPassword
            };
            let res = await ResetPassword(body);
            showToast(SUCCESS, "Password has been reset successfully !");
            history.push(getParamsAttachedRoute(APP_ROUTES.LOGIN));
        } catch (err) {
        } finally {
            setIsLoading(false);
        }
    };

    if (isAuthenticated) {
        return <Redirect to={APP_ROUTES.DASHBOARD} />;
    }

    return (
        <>
            <Helmet>
                <title>{PageTitles.RESET_PASSWORD}</title>
            </Helmet>
            <AuthWrapper>
                <div className="w-100">
                    <div className="w-100 d-flex align-items-center justify-content-center">
                        <Headings
                            title="RESET PASSWORD"
                            subText="Please enter your new password below."
                        />
                    </div>
                    <div className="w-100 d-flex align-items-center justify-content-center mt-4">
                        <form className="w-50" onSubmit={formik.handleSubmit}>
                            <Label>New Password</Label>
                            <div className="mb-4">
                                <PasswordInput
                                    placeholder="Enter new password"
                                    type="password"
                                    name="newPassword"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.password}
                                />
                                {formik.touched.newPassword && formik.errors.newPassword ? (
                                    <span className="error">{formik.errors.newPassword}</span>
                                ) : null}
                            </div>

                            <Label>Confirm New Password</Label>
                            <div className="mb-4">
                                <PasswordInput
                                    placeholder="Confirm new password"
                                    type="password"
                                    name="confirmPassword"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.confirmPassword}
                                />
                                {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                                    <span className="error">{formik.errors.confirmPassword}</span>
                                ) : null}
                            </div>

                            <CustomButton
                                loading={isLoading}
                                color="primary"
                                type={"submit"}
                                title="RESET PASSWORD"
                                className="w-100 mt-3"
                            />
                            <div className="d-flex align-items-center justify-content-center my-4">
                                <Link to={APP_ROUTES.LOGIN}>
                                    <p className="clr-theme-primary fw-bold text-decoration-underline">
                                        {" "}
                                        Back to Login{" "}
                                    </p>
                                </Link>
                            </div>
                        </form>
                    </div>
                </div>
                <CustomModal isOpen={showResetSuccessModal} size="md">
                    <div className="text-center">
                        <img src={successImg} className="mb-4" />
                        <h4 className="font-clr-theme-secondary fw-bold">
                            Password Reset Successfully
                        </h4>
                        <p className="my-4 font-clr-theme-secondary">
                            Your password has been reset
                        </p>
                        <CustomButton
                            color="primary"
                            title="Back to Login"
                            className="custom-btn-sm"
                            onClick={() => history.push(getParamsAttachedRoute(APP_ROUTES.LOGIN))}
                        />
                    </div>
                </CustomModal>
            </AuthWrapper>
        </>
    );
};

export default withRouter(ResetPasswordView);
