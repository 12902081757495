export const APP_ROUTES = {
    // Authentication
    LOGIN: "/login",
    LOGOUT: "/logout",
    FORGET_PASSWORD: "/forgot-password",
    FORGET_VERIFY_CODE: "/forget-verify-code/:tokenId",
    RESET_PASSWORD: "/reset-password/:tokenUUId",
    REGISTER: "/register",

    // Dashboard
    DASHBOARD: "/dashboard",

    // User Management
    USER_MANAGEMENT: "/guest-users",

    // Provider Management
    PROVIDER_MANAGEMENT: "/provider-management",
    PROVIDER_MANAGEMENT_DETAILS: "/provider-management/:providerId",
    PROVIDER_DETAILS: "/provider-management/:providerId/details",
    PROVIDER_DETAILS_CATANDAVAIL: "/provider-management/:providerId/categories-and-availibility",
    PROVIDER_DETAILS_JOBS: "/provider-management/:providerId/jobs",
    PROVIDER_DETAILS_PROMOTIONS: "/provider-management/:providerId/promotions",
    PROVIDER_DETAILS_RATANDREVIEWS: "/provider-management/:providerId/rating-and-reviews",
    PROVIDER_MANAGEMENT_UPDATE: "/provider-management/update/:providerId",

    // Seeker Management
    SEEKER_MANAGEMENT: "/seeker-management",
    SEEKER_MANAGEMENT_DETAILS: "/seeker-management/:seekerId",
    SEEKER_PROFILE_DETAILS: "/seeker-management/:seekerId/details",
    SEEKER_DETAILS_CARE_PROFILE: "/seeker-management/:seekerId/care-profile",
    SEEKER_DETAILS_JOBS: "/seeker-management/:seekerId/jobs",
    SEEKER_DETAILS_RATANDREVIEWS: "/seeker-management/:seekerId/rating-and-reviews",
    SEEKER_MANAGEMENT_UPDATE: "/seeker-management/update/:seekerId",

    //Category Management
    CATEGORY_MANAGEMENT: "/category-management",
    CATEGORY_MANAGEMENT_DETAILS: "/category-management/:categoryId",

    //Job Listing
    JOB_LISTING: "/jobs",
    JOB_LISTING_DETAIL: "/jobs/:jobId",

    // Account
    ACCOUNT: "/account/update"
    // ACCOUNT_PROFILE: "/account/profile",
    // UPDATE_ACCOUNT_PROFILE: "/account/profile/update",
    // ACCOUNT_CHANGE_PASSWORD: "/account/update"
};

export const getParamsAttachedRoute = (route, obj = {}) => {
    let keys = Object.keys(obj);

    if (keys?.length) {
        let objectKeys = keys;

        objectKeys.forEach(() => {
            route = route.replace(new RegExp(/:([\d\w?])+/, "i"), (match) => {
                let formattedMatchedValue =
                    match[match.length - 1] === "?"
                        ? match.slice(1, match.length - 1)
                        : match.slice(1);
                return obj[formattedMatchedValue];
            });
        });
        return route;
    }
    return route;
};
