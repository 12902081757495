import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col } from "reactstrap";
import { useHistory } from "react-router-dom";

import viewIcon from "../../assets/images/app/common/listing-view-image.svg";

import { SearchBox } from "../../components/SearchBox";
import { Pagination } from "../../components/Pagination";
import { DataTable } from "../../components/DataTable";
import { APP_ROUTES, getParamsAttachedRoute } from "../../helpers/routeHelpers";
import { GetSeekers } from "../../api/api.service";
import { MODULE_KEYS, getKey } from "../../api/api.keys";
import { useLocalPaginatedRequest } from "../../hooks/useLocalPaginatedRequest";
import { PageTitles } from "../../constants";
import { monthYear } from "../../utils/dateUtils";
import { truncateString } from "../../utils/commonUtils";
import { dateTimeFormat } from "../../utils/dateFormatUtils";

const COLUMNS = {
    S_NO: "S.No",
    NAME: "Name",
    EMAIL: "Email",
    CONTACT_NUMBER: "Contact Number",
    CITY: "City",
    JOINED_ON: "Joined On",
    ACTION: "Action"
};

const SeekerManagement = () => {
    const history = useHistory();

    const {
        isFetching,
        page,
        total,
        data,
        limit,
        searchText,
        handlePageClick,
        handleSearchChange,
        handleSortingChange
    } = useLocalPaginatedRequest({
        key: getKey([MODULE_KEYS.SEEKER_MANAGEMENT_LISTING]),
        dataKey: "seekers",
        totalKey: "total",
        requestFn: GetSeekers,
        params: {}
    });
    return (
        <div className="page-content">
            <Helmet>
                <title>{PageTitles.PROVIDER_MANAGEMENT}</title>
            </Helmet>
            <Container fluid>
                <Row>
                    <Col sm="12" md="3" lg="3">
                        <SearchBox
                            className="me-3 w-50 my-1"
                            value={searchText}
                            onChange={handleSearchChange}
                        />
                    </Col>
                    <Col sm="12" md="6" lg="6"></Col>
                    <Col sm="12" md="3" lg="3"></Col>
                </Row>

                <DataTable
                    tableContainerClass="mt-3"
                    loading={isFetching}
                    data={data}
                    config={[
                        {
                            title: COLUMNS.S_NO,
                            render: (data) => data?.id || "-"
                        },

                        {
                            title: COLUMNS.NAME,
                            render: (data) => truncateString(data.name, 30) || "-"
                        },

                        {
                            title: COLUMNS.EMAIL,
                            render: (data) => <div>{truncateString(data.email, 30)}</div> || "-"
                        },
                        {
                            title: COLUMNS.CONTACT_NUMBER,
                            render: (data) => (
                                <div>
                                    {data?.countryCode && data?.phone
                                        ? `${data?.countryCode}${data?.phone}`
                                        : "-"}
                                </div>
                            )
                        },
                        {
                            title: COLUMNS.CITY,
                            render: (data) => data?.location?.city || "-"
                        },

                        {
                            title: COLUMNS.JOINED_ON,

                            render: (data) =>
                                monthYear(dateTimeFormat.appDateFormat, data.createdAt) || "-"
                        },

                        {
                            title: COLUMNS.ACTION,
                            render: (data) => {
                                return (
                                    <div className="d-flex ">
                                        <img
                                            style={{
                                                filter: "brightness(0) saturate(100%) invert(58%) sepia(21%) saturate(708%) hue-rotate(283deg) brightness(83%) contrast(82%)"
                                            }}
                                            className="me-3"
                                            src={viewIcon}
                                            alt={"details icon"}
                                            role="button"
                                            onClick={() =>
                                                history.push(
                                                    getParamsAttachedRoute(
                                                        APP_ROUTES.SEEKER_MANAGEMENT_DETAILS,
                                                        {
                                                            seekerId: data?.id
                                                        }
                                                    )
                                                )
                                            }
                                        />
                                    </div>
                                );
                            }
                        }
                    ]}
                />

                <Pagination
                    rowLimit={limit}
                    onPageClick={handlePageClick}
                    currentPage={page}
                    totalData={total}
                    handleChange={(e) => handleSortingChange(e.target.value)}
                />
            </Container>
        </div>
    );
};
export default SeekerManagement;
