import loginLeftSectionBgImg from "./assets/images/app/auth-page/login-left-bg.png";
import sideBarLogoImg from "./assets/images/app/common/pured-logo-white.svg";
// import logo from "./assets/images/app/common/pured-logo.svg";
import logo from "./assets/images/logo/our-village-logo.svg";

import avatarPlaceholder from "./assets/images/app/common/avatar-paceholder.png";
const APP_CONFIG = {
    APP_NAME: "Pured Disc Golf",
    AUTH_LEFT_TEXT: "",
    LOGIN_LEFT_MAIN_TEXT: "",
    LOGIN_LEFT_SUB_TEXT: "",
    OPEN_SIDEBAR_APP_LOGO_SIZE: { width: "70", height: "70" },
    COLLAPSED_SIDEBAR_APP_LOGO: { width: "45", height: "40" },

    IMAGES: {
        APP_LOGO: logo,
        OPEN_SIDEBAR_APP_LOGO: sideBarLogoImg,
        COLLAPSED_SIDEBAR_APP_LOGO: sideBarLogoImg,
        LOGIN_LEFT_BG_IMAGE: loginLeftSectionBgImg,
        // AVATAR_PLACEHOLDER: logo,
        AVATAR_PLACEHOLDER: avatarPlaceholder,

        AUTH_LEFT_OVERLAY_IMAGE: "",
        LOGIN_RIGHT_IMAGE: "",
        LOGIN_LEFT_MAIN_IMAGE: logo,
        ICON_PLACEHOLDER: "https://icons8.com/icon/OZzk5umGngVd/icon"
        // ICON_PLACEHOLDER: logo
    },

    IS_FOOTER: false,
    FOOTER_CONTENT: "",
    IS_VERTICAL_LAYOUT_NOTIFIACTION: false,
    IS_HORIZONTAL_LAYOUT_NOTIFIACTION: false
};

export default APP_CONFIG;
