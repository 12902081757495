import React from "react";
import { CardTitle, Row } from "reactstrap";

import { DataTable } from "../../components/DataTable";
import { SummaryCard } from "../../components/SummaryCard";
import { monthYear } from "../../utils/dateUtils";
import { dateTimeFormat } from "../../utils/dateFormatUtils";
import { formatString } from "../../utils/commonUtils";

const COLUMNS = {
    APPLICANT_NAME: "Applicant Name",
    APPLICATION_DATE: "Application Date",
    GENDER: "Gender"
};

const JobApplicants = ({ data }) => {
    return (
        <SummaryCard>
            <Row className="">
                <CardTitle className="text-left">Applicants({data?.length})</CardTitle>
                <DataTable
                    tableContainerClass=""
                    data={data}
                    config={[
                        {
                            title: COLUMNS.APPLICANT_NAME,
                            render: (data) => data?.user?.name || "-"
                        },
                        {
                            title: COLUMNS.APPLICATION_DATE,
                            render: (data) =>
                                monthYear(dateTimeFormat.appDateFormat, data?.createdAt) || "-"
                        },
                        {
                            title: COLUMNS.GENDER,
                            render: (data) => formatString(data?.user?.gender) || "-"
                        }
                    ]}
                    isHeightRequired={false}
                />
            </Row>
        </SummaryCard>
    );
};

export default JobApplicants;
