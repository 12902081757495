import React from "react";

import InfoCard from "./InfoCard";
import JobDetailsCard from "./JobDetailsCard";
import JobApplicants from "./JobApplicants";
import JobRatAndReviews from "./JobRatAndReviews";
import { JOBS_STATUS } from "../../constants/JobsStatus";

const JobDetails = ({ data, isSuccess }) => {
    return (
        <div className="my-4">
            <InfoCard
                seeker={data?.user}
                provider={data?.assignee}
                job={data}
                status={data?.status}
            />
            <JobDetailsCard data={isSuccess && data} />

            {data?.jobApplicants?.length > 0 && <JobApplicants data={data?.jobApplicants} />}
            {data?.status != JOBS_STATUS.EXPIRED && (
                <JobRatAndReviews
                    providerRating={data?.providerRating}
                    seekerRating={data?.seekerRating}
                />
            )}
        </div>
    );
};

export default JobDetails;
