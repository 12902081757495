import React from "react";

import CustomModal from "./CustomModal";
import { CustomButton } from "../CustomButton";
import { CONFIRMATION_MODAL_CONFIG, getConfirmationModalConfig } from "./ConfirmationModalConfig";

const ConfirmationModal = ({
    isOpen = false,
    modalType = "DELETE",
    hasCustomConfig = false,
    customTitle = "",
    customYesBtnText = "",
    customNoBtnText = "",
    customImageSrc = "",
    onYes = () => {},
    onNo = () => {},
    yesLoading = false,
    noLoading = false,
    ...rest
}) => {
    let customConfigObj = hasCustomConfig
        ? {
              customTitle,
              customYesBtnText,
              customNoBtnText,
              customImageSrc
          }
        : null;

    let Config = getConfirmationModalConfig(CONFIRMATION_MODAL_CONFIG, modalType, customConfigObj);

    return (
        <div>
            <CustomModal isOpen={isOpen} handleModalToggling={onNo} customButton={false} {...rest}>
                <div className="d-flex flex-column align-items-center">
                    <img src={Config.ImageSrc} className="mb-5" />

                    <div className="mb-5">
                        <h4 className="font-clr-theme-secondary fw-bold text-center">
                            {Config.Title}
                        </h4>
                        {Config.SubText && (
                            <p className="my-4 font-clr-theme-secondary">{Config.SubText}</p>
                        )}
                    </div>

                    <div className="d-flex justify-content-evenly btns-main-con ">
                        <CustomButton
                            title={Config.YesButtonText}
                            color="primary"
                            className="custom-btn-sm shadow-theme fw-bold"
                            onClick={onYes}
                            loading={yesLoading}
                        />
                        <CustomButton
                            title={Config.NoButtonText}
                            className="me-3 custom-btn-sm fw-bold"
                            color="primary"
                            outline
                            onClick={onNo}
                            loading={noLoading}
                            disabled={yesLoading}
                        />
                    </div>
                </div>
            </CustomModal>
        </div>
    );
};

export default ConfirmationModal;
