// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-top-back-btn {
  font-weight: 400;
  text-transform: capitalize;
  color: #495057;
  display: inline-block;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/HeaderBackButton/Style.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,0BAAA;EACA,cAAA;EACA,qBAAA;EACA,eAAA;AACJ","sourcesContent":[".table-top-back-btn {\n    font-weight: 400;\n    text-transform: capitalize;\n    color: #495057;\n    display: inline-block;\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
