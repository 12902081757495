import { CategoryStatus } from "../../constants";

const CategoryActions = {
    ACTIVATE: 1,
    DEACTIVATE: 0
};

export const getStatus = (status) =>
    status === CategoryStatus.ACTIVE ? CategoryStatus.INACTIVE : CategoryStatus.ACTIVE;
export const getStatusRunningOrCanceled = (status) =>
    status === CategoryStatus.ACTIVE ? CategoryStatus.INACTIVE : CategoryStatus.ACTIVE;
export const findStatusChecked = (status) =>
    status === CategoryStatus.ACTIVE ? CategoryActions.ACTIVATE : CategoryActions.DEACTIVATE;
export const handleAdModal = (setState, state) => setState(!state);
