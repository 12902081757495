// import React, { useState, useEffect, useRef } from "react";
// import { Helmet } from "react-helmet";
// import { useSelector } from "react-redux";
// import { withRouter, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
// import { Row, Col } from "reactstrap";

// import AuthWrapper from "./AuthWrapper";
// import { CodeInput } from "../../components/CodeInput";
// import { CustomButton } from "../../components/CustomButton";
// import { APP_ROUTES, getParamsAttachedRoute } from "../../helpers/routeHelpers";
// import { ForgotPasswordVerification, ForgotPassword } from "../../api/api.service";
// import { PageTitles } from "../../constants";
// import Headings from "./Headings";
// import { SUCCESS, showToast } from "../../utils/toastUtils";

// const OTP_LENGTH = 4;
// const RESEND_INTERVAL = 60; // 60 seconds

// const ForgetVerifyCode = ({}) => {
//     const history = useHistory();
//     const location = useLocation();
//     const { tokenId } = useParams();
//     const isAuthenticated = useSelector((state) => state?.userAuth?.isAuthenticated);

//     const otpRef = useRef(null);
//     const [isLoading, setIsLoading] = useState(false);
//     const [OTP, setOTP] = useState("");
//     const [email, setEmail] = useState("");
//     const [token, setToken] = useState("");
//     const [codeAndToken, setCodeAndToken] = useState({ tokenUUId: "" });
//     const [isResendCode, setIsResendCode] = useState(true);
//     const [timer, setTimer] = useState(RESEND_INTERVAL);

//     useEffect(() => {
//         let interval;
//         if (tokenId) {
//             setToken(tokenId);
//             setEmail(location?.state?.email || "");
//         }
//         if (isResendCode) {
//             interval = setInterval(() => {
//                 setTimer((prev) => {
//                     if (prev <= 1) {
//                         clearInterval(interval);
//                         setIsResendCode(false);
//                         return RESEND_INTERVAL;
//                     }
//                     return prev - 1;
//                 });
//             }, 1000);
//         }

//         return () => clearInterval(interval);
//     }, [isResendCode]);

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         try {
//             setIsLoading(true);

//             const body = { code: OTP, verificationToken: token };
//             const res = await ForgotPasswordVerification(body);
//             const { verificationToken } = res;
//             setCodeAndToken({ tokenUUId: verificationToken });

//             history.push(
//                 getParamsAttachedRoute(APP_ROUTES.RESET_PASSWORD, { tokenUUId: verificationToken })
//             );
//         } catch (err) {
//         } finally {
//             setIsLoading(false);
//         }
//     };

//     const handleResendCode = async () => {
//         try {
//             setIsResendCode(true);
//             const res = await ForgotPassword({ email: email });
//             otpRef.current?.clear();
//             showToast(SUCCESS, "An OTP has been sent to your email address!");
//             history.replace({
//                 pathname: getParamsAttachedRoute(APP_ROUTES.FORGET_VERIFY_CODE, {
//                     tokenId: res?.verificationToken
//                 }),
//                 state: { email }
//             });
//         } finally {
//             setOTP("");
//         }
//     };

//     if (!location?.state?.email) {
//         return <Redirect to={APP_ROUTES.DASHBOARD} />;
//     }

//     if (isAuthenticated) {
//         return <Redirect to={APP_ROUTES.DASHBOARD} />;
//     }

//     return (
//         <>
//             <Helmet>
//                 <title>{PageTitles.OTP_VERIFICATION}</title>
//             </Helmet>
//             <AuthWrapper>
//                 <div className="bv-forget-wrap">
//                     <div className="w-100 d-flex align-items-center justify-content-center">
//                         <Headings
//                             title="VERIFY OTP"
//                             subText="We have sent an OTP to your email address. Please enter the OTP below to verify your identity."
//                         />
//                     </div>

//                     <div className="w-100 d-flex align-items-center justify-content-center mt-4">
//                         <form className="w-50" onSubmit={handleSubmit}>
//                             <Row>
//                                 <Col md={12}>
//                                     <CodeInput
//                                         className="my-4 text-center"
//                                         onChange={(e) => setOTP(e)}
//                                         ref={otpRef}
//                                     />
//                                 </Col>
//                             </Row>
//                             <CustomButton
//                                 disabled={OTP.length !== OTP_LENGTH}
//                                 loading={isLoading}
//                                 onClick={handleSubmit}
//                                 color="primary"
//                                 type="submit"
//                                 title="Verify"
//                                 className="w-100 mt-4"
//                             />
//                         </form>
//                     </div>
//                     <div className="d-flex mt-4 align-items-center justify-content-center responsiveness">
//                         <p className="font-clr-theme-secondary fw-bold">Didn’t Receive Yet?</p>
//                         <p
//                             className={`clr-theme-primary ms-1 fw-bold ${
//                                 !isResendCode ? "cursor-pointer text-decoration-underline" : ""
//                             }`}
//                             onClick={!isResendCode ? handleResendCode : null}
//                         >
//                             {isResendCode ? `Resend OTP in (${timer} sec)` : "Resend Now"}
//                         </p>
//                     </div>
//                     <div className="d-flex align-items-center justify-content-center my-4">
//                         <Link to={APP_ROUTES.LOGIN}>
//                             <p className="clr-theme-primary fw-bold text-decoration-underline">
//                                 Back to Login
//                             </p>
//                         </Link>
//                     </div>
//                 </div>
//             </AuthWrapper>
//         </>
//     );
// };

// export default withRouter(ForgetVerifyCode);

import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { withRouter, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import { Row, Col } from "reactstrap";

import AuthWrapper from "./AuthWrapper";
import { CodeInput } from "../../components/CodeInput";
import { CustomButton } from "../../components/CustomButton";
import { APP_ROUTES, getParamsAttachedRoute } from "../../helpers/routeHelpers";
import { ForgotPasswordVerification, ForgotPassword } from "../../api/api.service";
import { PageTitles } from "../../constants";
import Headings from "./Headings";
import { SUCCESS, showToast } from "../../utils/toastUtils";

const OTP_LENGTH = 4;
const RESEND_INTERVAL = 60; // 60 seconds

const ForgetVerifyCode = ({}) => {
    const history = useHistory();
    const location = useLocation();
    const { tokenId } = useParams();
    const isAuthenticated = useSelector((state) => state?.userAuth?.isAuthenticated);

    const otpRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [OTP, setOTP] = useState("");
    const [email, setEmail] = useState("");
    const [token, setToken] = useState("");
    const [codeAndToken, setCodeAndToken] = useState({ tokenUUId: "" });
    const [isResendCode, setIsResendCode] = useState(true);
    const [timer, setTimer] = useState(RESEND_INTERVAL);

    useEffect(() => {
        let interval;
        const savedTimer = localStorage.getItem("otpTimer");
        const lastTime = localStorage.getItem("lastTime");
        const timeElapsed = Math.floor((Date.now() - lastTime) / 1000);

        if (savedTimer && timeElapsed < RESEND_INTERVAL) {
            setTimer(Math.max(0, savedTimer - timeElapsed));
        }

        if (tokenId) {
            setToken(tokenId);
            setEmail(location?.state?.email || "");
        }

        if (isResendCode) {
            interval = setInterval(() => {
                setTimer((prev) => {
                    const updatedTime = prev - 1;
                    localStorage.setItem("otpTimer", updatedTime);
                    localStorage.setItem("lastTime", Date.now());

                    if (updatedTime <= 0) {
                        clearInterval(interval);
                        setIsResendCode(false);
                        localStorage.removeItem("otpTimer");
                        localStorage.removeItem("lastTime");
                        return RESEND_INTERVAL;
                    }
                    return updatedTime;
                });
            }, 1000);
        }

        return () => clearInterval(interval);
    }, [isResendCode]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setIsLoading(true);

            const body = { code: OTP, verificationToken: token };
            const res = await ForgotPasswordVerification(body);
            const { verificationToken } = res;
            setCodeAndToken({ tokenUUId: verificationToken });

            history.push(
                getParamsAttachedRoute(APP_ROUTES.RESET_PASSWORD, { tokenUUId: verificationToken })
            );
        } catch (err) {
        } finally {
            setIsLoading(false);
        }
    };

    const handleResendCode = async () => {
        try {
            setIsResendCode(true);
            const res = await ForgotPassword({ email: email });
            otpRef.current?.clear();
            showToast(SUCCESS, "An OTP has been sent to your email address!");
            history.replace({
                pathname: getParamsAttachedRoute(APP_ROUTES.FORGET_VERIFY_CODE, {
                    tokenId: res?.verificationToken
                }),
                state: { email }
            });
        } finally {
            setOTP("");
        }
    };

    if (!location?.state?.email) {
        return <Redirect to={APP_ROUTES.DASHBOARD} />;
    }

    if (isAuthenticated) {
        return <Redirect to={APP_ROUTES.DASHBOARD} />;
    }

    return (
        <>
            <Helmet>
                <title>{PageTitles.OTP_VERIFICATION}</title>
            </Helmet>
            <AuthWrapper>
                <div className="bv-forget-wrap">
                    <div className="w-100 d-flex align-items-center justify-content-center">
                        <Headings
                            title="VERIFY OTP"
                            subText="We have sent an OTP to your email address. Please enter the OTP below to verify your identity."
                        />
                    </div>

                    <div className="w-100 d-flex align-items-center justify-content-center mt-4">
                        <form className="w-50" onSubmit={handleSubmit}>
                            <Row>
                                <Col md={12}>
                                    <CodeInput
                                        className="my-4 text-center"
                                        onChange={(e) => setOTP(e)}
                                        ref={otpRef}
                                    />
                                </Col>
                            </Row>
                            <CustomButton
                                disabled={OTP.length !== OTP_LENGTH}
                                loading={isLoading}
                                onClick={handleSubmit}
                                color="primary"
                                type="submit"
                                title="Verify"
                                className="w-100 mt-4"
                            />
                        </form>
                    </div>
                    <div className="d-flex mt-4 align-items-center justify-content-center responsiveness">
                        <p className="font-clr-theme-secondary fw-bold">Didn’t Receive Yet?</p>
                        <p
                            className={`clr-theme-primary ms-1 fw-bold ${
                                !isResendCode ? "cursor-pointer text-decoration-underline" : ""
                            }`}
                            onClick={!isResendCode ? handleResendCode : null}
                        >
                            {isResendCode ? `Resend OTP in (${timer} sec)` : "Resend Now"}
                        </p>
                    </div>
                    <div className="d-flex align-items-center justify-content-center my-4">
                        <Link to={APP_ROUTES.LOGIN}>
                            <p className="clr-theme-primary fw-bold text-decoration-underline">
                                Back to Login
                            </p>
                        </Link>
                    </div>
                </div>
            </AuthWrapper>
        </>
    );
};

export default withRouter(ForgetVerifyCode);
