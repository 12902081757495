import { injectBaseConstantMethods } from "./BaseConstants";

const Roles = {
    Admin: "ADMIN",
    USER: "USER",
    SEEKER: "SEEKER",
    PROVIDER: "PROVIDER",
    GUEST: "GUEST "
};

const displayTextKeys = {
    [Roles.Admin]: "Admin",
    [Roles.USER]: "User",
    [Roles.SEEKER]: "Seeker",
    [Roles.PROVIDER]: "Provider",
    [Roles.GUEST]: "Guest"
};

const labelClass = {
    [Roles.Admin]: "admin",
    [Roles.SuperAdmin]: "super-admin"
};

export default injectBaseConstantMethods(Roles, displayTextKeys, labelClass);
