// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-custom-text .center {
  min-width: 50%;
}
.app-custom-text.border-right, .app-custom-text.border-left {
  position: relative;
}
.app-custom-text.border-right::after, .app-custom-text.border-left::after {
  content: "";
  width: 1px;
  height: 44px;
  background: rgba(149, 149, 149, 0.8);
  opacity: 0.1;
  position: absolute;
}

/* Media query for mobile devices */
@media (max-width: 767px) {
  .app-custom-text.border-right::after,
  .app-custom-text.border-left::after {
    display: none; /* Hide borders on mobile devices */
  }
}
/* Media query for tablets and small laptops */
@media (min-width: 768px) and (max-width: 1023px) {
  .app-custom-text.border-right::after,
  .app-custom-text.border-left::after {
    display: none; /* Hide borders on tablet devices */
  }
}
/* Media query for regular laptops and desktops */
@media (min-width: 1024px) and (max-width: 1279px) {
  /* Styles for regular laptops and desktops */
}
/* Media query for larger desktop screens */
@media (min-width: 1280px) {
  /* Styles for larger desktop screens */
}`, "",{"version":3,"sources":["webpack://./src/components/CustomText/Style.scss"],"names":[],"mappings":"AACI;EACI,cAAA;AAAR;AAEI;EAEI,kBAAA;AADR;AAEQ;EACI,WAAA;EACA,UAAA;EACA,YAAA;EACA,oCAAA;EACA,YAAA;EACA,kBAAA;AAAZ;;AAKA,mCAAA;AACA;EACI;;IAEI,aAAA,EAAA,mCAAA;EAFN;AACF;AAKA,8CAAA;AACA;EACI;;IAEI,aAAA,EAAA,mCAAA;EAHN;AACF;AAMA,iDAAA;AACA;EACI,4CAAA;AAJJ;AAOA,2CAAA;AACA;EACI,sCAAA;AALJ","sourcesContent":[".app-custom-text {\n    .center {\n        min-width: 50%;\n    }\n    &.border-right,\n    &.border-left {\n        position: relative;\n        &::after {\n            content: \"\";\n            width: 1px;\n            height: 44px;\n            background: rgba(149, 149, 149, 0.8);\n            opacity: 0.1;\n            position: absolute;\n        }\n    }\n}\n\n/* Media query for mobile devices */\n@media (max-width: 767px) {\n    .app-custom-text.border-right::after,\n    .app-custom-text.border-left::after {\n        display: none; /* Hide borders on mobile devices */\n    }\n}\n\n/* Media query for tablets and small laptops */\n@media (min-width: 768px) and (max-width: 1023px) {\n    .app-custom-text.border-right::after,\n    .app-custom-text.border-left::after {\n        display: none; /* Hide borders on tablet devices */\n    }\n}\n\n/* Media query for regular laptops and desktops */\n@media (min-width: 1024px) and (max-width: 1279px) {\n    /* Styles for regular laptops and desktops */\n}\n\n/* Media query for larger desktop screens */\n@media (min-width: 1280px) {\n    /* Styles for larger desktop screens */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
