// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sorting-con {
  color: #000;
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 15px;
}
.sorting-con .sorting-dropdown {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.2px;
  color: #8a5b6f;
  border: 1px solid #8a5b6f;
}
.sorting-con .sorting-dropdown:hover option:hover {
  background-color: #e0e0e0; /* Change the background color for hover */
  color: #333; /* Change the text color for hover */
}`, "",{"version":3,"sources":["webpack://./src/components/RowsLimit/RowsLimit.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,sBAAA;EACA,eAAA;EACA,gBAAA;EACA,mBAAA;AACJ;AAAI;EACI,sBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,qBAAA;EACA,cAAA;EACA,yBAAA;AAER;AAAI;EACI,yBAAA,EAAA,0CAAA;EACA,WAAA,EAAA,oCAAA;AAER","sourcesContent":[".sorting-con {\n    color: #000;\n    font-family: \"Poppins\";\n    font-size: 16px;\n    font-weight: 500;\n    margin-bottom: 15px;\n    .sorting-dropdown {\n        font-family: \"Poppins\";\n        font-weight: 500;\n        font-size: 14px;\n        line-height: 19px;\n        letter-spacing: 0.2px;\n        color: #8a5b6f;\n        border: 1px solid #8a5b6f;\n    }\n    .sorting-dropdown:hover option:hover {\n        background-color: #e0e0e0; /* Change the background color for hover */\n        color: #333; /* Change the text color for hover */\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
