import React from "react";
import { Col, Container, Row } from "reactstrap";

import SummaryCard from "../../components/SummaryCard/SummaryCard";
import { monthYear } from "../../utils/dateUtils";
import { dateTimeFormat } from "../../utils/dateFormatUtils";

const format = dateTimeFormat.appDateFormat;
const timeFormatString = "ha";

const InfoCard = ({ seeker, provider, job, status }) => {
    return (
        <>
            <Container fluid>
                <Row>
                    {/* Service Seeker Information */}
                    <Col sm={6} md={4}>
                        <SummaryCard
                            title="Service Seeker Information:"
                            titleTag="h6"
                            colProps={{ md: 12 }}
                            cardProps={{
                                className: "border rounded p-3 mx-2 h-80 d-flex flex-column"
                            }}
                            cardBodyProps={{ className: "p-0 flex-grow-1" }}
                        >
                            <div className="my-1 font-size-13 app-text-gray ">
                                Name:
                                {seeker?.name ? (
                                    <span className="profile-name-text font-size-15 fw-bold mx-2">
                                        {seeker.name}
                                    </span>
                                ) : (
                                    <span className="mx-2">{"-"}</span>
                                )}
                            </div>

                            {/* <div className="my-1 font-size-13 app-text-gray">
                                    Cancellation Rate: {seeker.cancellationRate}%
                                </div> */}
                            <div className="my-1 font-size-11 app-text-gray">
                                Rating:{" "}
                                <span className="mx-1 font-size-13">
                                    {seeker?.avgRating || "-"}
                                </span>
                            </div>
                        </SummaryCard>
                    </Col>
                    {/* Service Provider Information */}
                    <Col sm={6} md={4}>
                        <SummaryCard
                            title="Service Provider Information:"
                            titleTag="h6"
                            colProps={{ md: 12 }}
                            cardProps={{
                                className: "border rounded p-3 mx-2 h-80 d-flex flex-column"
                            }}
                            cardBodyProps={{ className: "p-0 flex-grow-1" }}
                        >
                            <div className="d-flex align-items-end mb-1">
                                <div className="my-1 font-size-13 app-text-gray">
                                    Name:
                                    {provider?.name ? (
                                        <span className="profile-name-text font-size-15 fw-bold mx-2">
                                            {provider.name}
                                        </span>
                                    ) : (
                                        <span className="mx-2">{"-"}</span>
                                    )}
                                </div>
                            </div>

                            <div className="my-1 font-size-11 app-text-gray">
                                Rating:{" "}
                                <span className="mx-1 font-size-13">
                                    {" "}
                                    {provider?.avgRating || "-"}{" "}
                                </span>
                            </div>
                        </SummaryCard>
                    </Col>
                    <Col sm={6} md={4}>
                        {/* Job Status */}
                        <SummaryCard
                            title={`Job Information:`}
                            titleTag="h6"
                            colProps={{ md: 12 }}
                            cardProps={{
                                className: "border rounded p-3 mx-2 h-80 d-flex flex-column"
                            }}
                            cardBodyProps={{ className: "p-0 flex-grow-1" }}
                        >
                            <div className="my-2"></div>
                            <div className="my-1 font-size-11 app-text-gray">
                                Job Starts on:{" "}
                                <span className="fw-normal font-size-13">
                                    {monthYear(format, job?.startDate)}
                                </span>
                            </div>

                            <div className="my-1 font-size-11 app-text-gray">
                                Job Start Time:{" "}
                                <span className="fw-normal font-size-13">
                                    {" "}
                                    {monthYear(timeFormatString, job?.startTime) || (
                                        <span className="mx-2">{"N/A"} </span>
                                    )}{" "}
                                </span>
                            </div>
                        </SummaryCard>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default InfoCard;
