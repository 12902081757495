// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slider-container {
  width: 100%;
  height: 100%;
}

.image-container {
  width: 100%;
  height: 400px;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.slider-img {
  object-fit: contain;
}

.slick-dots {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  width: 100%;
  display: flex !important;
  flex-direction: row;
  justify-content: center;
  position: unset !important;
  margin-top: 20px !important;
}

.slick-dots li {
  width: 30px !important;
  height: 30px !important;
  list-style: none;
}

.slick-dots li button {
  border: none;
  background: transparent;
  padding: 0;
}

.slick-dots li img {
  border-radius: 4px;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/ImageGallery/Style.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,YAAA;AACJ;;AAEA;EACI,WAAA;EACA,aAAA;EACA,wBAAA;EACA,mBAAA;EACA,uBAAA;AACJ;;AACA;EACI,mBAAA;AAEJ;;AACA;EACI,gBAAA;EACA,kBAAA;EACA,mBAAA;EACA,WAAA;EACA,wBAAA;EACA,mBAAA;EACA,uBAAA;EACA,0BAAA;EACA,2BAAA;AAEJ;;AACA;EACI,sBAAA;EACA,uBAAA;EACA,gBAAA;AAEJ;;AACA;EACI,YAAA;EACA,uBAAA;EACA,UAAA;AAEJ;;AACA;EACI,kBAAA;EACA,eAAA;AAEJ","sourcesContent":[".slider-container {\n    width: 100%;\n    height: 100%;\n}\n\n.image-container {\n    width: 100%;\n    height: 400px;\n    display: flex !important;\n    align-items: center;\n    justify-content: center;\n}\n.slider-img {\n    object-fit: contain;\n}\n\n.slick-dots {\n    overflow-x: auto;\n    overflow-y: hidden;\n    white-space: nowrap;\n    width: 100%;\n    display: flex !important;\n    flex-direction: row;\n    justify-content: center;\n    position: unset !important;\n    margin-top: 20px !important;\n}\n\n.slick-dots li {\n    width: 30px !important;\n    height: 30px !important;\n    list-style: none;\n}\n\n.slick-dots li button {\n    border: none;\n    background: transparent;\n    padding: 0;\n}\n\n.slick-dots li img {\n    border-radius: 4px;\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
