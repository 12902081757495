// import React from "react";
// import PropTypes from "prop-types";
// import { Link, useLocation, useRouteMatch } from "react-router-dom";
// import { Row, Col, BreadcrumbItem } from "reactstrap";

// import { routeConfigs } from "./RouteConfigs";
// import logo from "../../assets/images/audit-icon.png";
// import { APP_ROUTES, getParamsAttachedRoute } from "../../helpers/routeHelpers";

// import "./Breadcrumb.scss";

// const Breadcrumb = (props) => {
//     const { title, breadcrumbItems } = props;
//     // const itemLength = breadcrumbItems.length;

//     const matches = useRouteMatch();

//     const { pathname } = useLocation();

//     let pathNameArr = pathname?.split("/");

//     const routeConfigs = [
//         {
//             path: APP_ROUTES.DASHBOARD,
//             title: "DASHBOARD",
//             count: "1",
//             matchUrl: "/dashboard",
//             key: "01"
//         },
//         {
//             path: APP_ROUTES.USER_MANAGEMENT,
//             title: "GUEST USERS",
//             count: "1",
//             matchUrl: "/users",
//             key: "02"
//         },

//         {
//             path: APP_ROUTES.PROVIDER_MANAGEMENT,
//             title: "PROVIDER MANAGEMENT",
//             count: "1",
//             matchUrl: "/provider-management",
//             key: "03"
//         },
//         {
//             path: getParamsAttachedRoute(APP_ROUTES.PROVIDER_DETAILS, {
//                 providerId: matches?.params?.providerId
//             }),
//             title: "PROFILE DETAILS",
//             count: "2",
//             matchUrl: "/provider-management/:providerId/details",
//             key: "04"
//         },
//         {
//             path: getParamsAttachedRoute(APP_ROUTES.PROVIDER_DETAILS_CATANDAVAIL, {
//                 providerId: matches?.params?.providerId
//             }),
//             title: "CATEGORIES AND AVAILIBILITY",
//             count: "2",
//             matchUrl: "/provider-management/:providerId/categories-and-availibility",
//             key: "05"
//         },
//         {
//             path: getParamsAttachedRoute(APP_ROUTES.PROVIDER_DETAILS_JOBS, {
//                 providerId: matches?.params?.providerId
//             }),
//             title: "JOBS",
//             count: "2",
//             matchUrl: "/provider-management/:providerId/jobs",
//             key: "06"
//         },
//         {
//             path: getParamsAttachedRoute(APP_ROUTES.PROVIDER_DETAILS_PROMOTIONS, {
//                 providerId: matches?.params?.providerId
//             }),
//             title: "PROMOTIONS",
//             count: "2",
//             matchUrl: "/provider-management/:providerId/promotions",
//             key: "07"
//         },
//         {
//             path: getParamsAttachedRoute(APP_ROUTES.PROVIDER_DETAILS_RATANDREVIEWS, {
//                 providerId: matches?.params?.providerId
//             }),
//             title: "RATING AND REVIEWS",
//             count: "2",
//             matchUrl: "/provider-management/:providerId/rating-and-reviews",
//             key: "08"
//         },

//         {
//             path: getParamsAttachedRoute(APP_ROUTES.PROVIDER_MANAGEMENT_UPDATE, {
//                 providerId: matches?.params?.providerId
//             }),
//             title: "UPDATE",
//             count: "2",
//             matchUrl: "/contents/update/:providerId",
//             key: "09"
//         },

//         {
//             path: APP_ROUTES.SEEKER_MANAGEMENT,
//             title: "SEEKER MANAGEMENT",
//             count: "1",
//             matchUrl: "/seeker-management",
//             key: "10"
//         },
//         {
//             path: getParamsAttachedRoute(APP_ROUTES.SEEKER_PROFILE_DETAILS, {
//                 seekerId: matches?.params?.seekerId
//             }),
//             title: "PROFILE",
//             count: "2",
//             matchUrl: "/seeker-management/:seekerId/details",
//             key: "11"
//         },
//         {
//             path: getParamsAttachedRoute(APP_ROUTES.SEEKER_DETAILS_CARE_PROFILE, {
//                 seekerId: matches?.params?.seekerId
//             }),
//             title: "CARE PROFILE",
//             count: "2",
//             matchUrl: "/seeker-management/:seekerId/care-profile",
//             key: "12"
//         },
//         {
//             path: getParamsAttachedRoute(APP_ROUTES.SEEKER_DETAILS_JOBS, {
//                 seekerId: matches?.params?.seekerId
//             }),
//             title: "JOBS",
//             count: "2",
//             matchUrl: "/seeker-management/:seekerId/jobs",
//             key: "13"
//         },
//         {
//             path: getParamsAttachedRoute(APP_ROUTES.SEEKER_DETAILS_RATANDREVIEWS, {
//                 seekerId: matches?.params?.seekerId
//             }),
//             title: "RATING AND REVIEWS",
//             count: "2",
//             matchUrl: "/seeker-management/:seekerId/rating-and-reviews",
//             key: "14"
//         },
//         {
//             path: APP_ROUTES.CATEGORY_MANAGEMENT,
//             title: "CATEGORY MANAGEMENT",
//             count: "1",
//             matchUrl: "/category-management",
//             key: "15"
//         },
//         {
//             path: getParamsAttachedRoute(APP_ROUTES.CATEGORY_MANAGEMENT_DETAILS, {
//                 categoryId: matches?.params?.categoryId
//             }),
//             title: "DETAILS",
//             count: "2",
//             matchUrl: "/category-management/:categoryId",
//             key: "16"
//         },
//         //JOB LISTINGS
//         {
//             path: APP_ROUTES.JOB_LISTING,
//             title: "JOB LISTING",
//             count: "1",
//             matchUrl: "/jobs",
//             key: "17"
//         },

//         {
//             path: getParamsAttachedRoute(APP_ROUTES.JOB_LISTING_DETAIL, {
//                 jobId: matches?.params?.jobId
//             }),
//             title: "DETAILS",
//             count: "2",
//             matchUrl: "/jobs/:jobId",
//             key: "18"
//         },
//         {
//             path: APP_ROUTES.ACCOUNT,
//             title: " CHANGE PASSWORD",
//             count: "1",
//             matchUrl: "/account/update",
//             key: "19"
//         }
//     ];

//     return (
//         // <Row>
//         //     <Col xs="12">
//         //         <div className="page-title-box d-flex align-items-center justify-content-between">
//         //             <h4 className="mb-0 font-size-18">{title}</h4>
//         //             <div className="page-title-right">
//         //                 <ol className="breadcrumb m-0">
//         //                     {breadcrumbItems.map((item, key) => (
//         //                         <BreadcrumbItem key={key} active={key + 1 === itemLength}>
//         //                             <Link to="#">{item.title}</Link>
//         //                         </BreadcrumbItem>
//         //                     ))}
//         //                 </ol>
//         //             </div>
//         //         </div>
//         //     </Col>
//         // </Row>

//         <Row className="breadcrumbs-con">
//             <Col xs="12" className="breadcrumbs-col">
//                 <div className="d-flex align-items-center justify-content-between">
//                     {/* <h4 className="mb-0 font-size-18">{title}</h4> */}
//                     <div className="page-title-right d-flex ">
//                         {routeConfigs.map((el, idx) => {
//                             if (pathname.startsWith(el.path)) {
//                                 if (el.count == "2") {
//                                     return (
//                                         <div
//                                             key={el.key}
//                                             className="d-flex align-items-center breadcrumb-max-width"
//                                         >
//                                             &nbsp; <span> / </span> &nbsp;
//                                             <div
//                                                 className="breadcrumb-typo breadcrumb-typo-bold"
//                                                 to={el.path}
//                                             >
//                                                 {el.title}
//                                             </div>
//                                         </div>
//                                     );
//                                 } else {
//                                     return (
//                                         <div key={el.key}>
//                                             {pathNameArr.length > 2 ? (
//                                                 <div className="breadcrumb-max-width">
//                                                     <Link className="breadcrumb-typo" to={el.path}>
//                                                         {el.title}
//                                                     </Link>
//                                                 </div>
//                                             ) : (
//                                                 <div>
//                                                     <div
//                                                         className="breadcrumb-typo breadcrumb-typo-bold breadcrumb-max-width"
//                                                         to={el.path}
//                                                     >
//                                                         {el.title}
//                                                     </div>
//                                                 </div>
//                                             )}
//                                         </div>
//                                     );
//                                 }
//                             } else {
//                                 if (pathname == el.path) {
//                                 }
//                             }
//                         })}

//                         {/* <ol className="breadcrumb m-0"> */}

//                         {/* {breadcrumbItems.map((item, key) => (
//                 <BreadcrumbItem key={key} active={key + 1 === itemLength}>
//                   <Link to="#">{item.title}</Link>
//                 </BreadcrumbItem>
//               ))} */}
//                         {/* </ol> */}
//                     </div>
//                 </div>
//             </Col>
//         </Row>
//     );
// };

// Breadcrumb.propTypes = {
//     breadcrumbItems: PropTypes.array,
//     title: PropTypes.string
// };

// export default Breadcrumb;

import React from "react";
import PropTypes from "prop-types";
import { Link, useLocation, useRouteMatch } from "react-router-dom";
import { Row, Col, Breadcrumb as ReactstrapBreadcrumb, BreadcrumbItem } from "reactstrap";

import { APP_ROUTES, getParamsAttachedRoute } from "../../helpers/routeHelpers";
import "./Breadcrumb.scss";

const Breadcrumb = ({ title, breadcrumbItems }) => {
    const matches = useRouteMatch();
    const { pathname } = useLocation();
    const pathNameArr = pathname?.split("/");

    const routeConfigs = [
        {
            path: APP_ROUTES.DASHBOARD,
            title: "DASHBOARD",
            count: "1",
            matchUrl: "/dashboard",
            key: "01"
        },
        {
            path: APP_ROUTES.USER_MANAGEMENT,
            title: "GUEST USERS",
            count: "1",
            matchUrl: "/users",
            key: "02"
        },

        {
            path: APP_ROUTES.PROVIDER_MANAGEMENT,
            title: "PROVIDER MANAGEMENT",
            count: "1",
            matchUrl: "/provider-management",
            key: "03"
        },
        {
            path: getParamsAttachedRoute(APP_ROUTES.PROVIDER_DETAILS, {
                providerId: matches?.params?.providerId
            }),
            title: "PROFILE DETAILS",
            count: "2",
            matchUrl: "/provider-management/:providerId/details",
            key: "04"
        },
        {
            path: getParamsAttachedRoute(APP_ROUTES.PROVIDER_DETAILS_CATANDAVAIL, {
                providerId: matches?.params?.providerId
            }),
            title: "SERVICES AND AVAILABILITY",
            count: "2",
            matchUrl: "/provider-management/:providerId/categories-and-availibility",
            key: "05"
        },
        {
            path: getParamsAttachedRoute(APP_ROUTES.PROVIDER_DETAILS_JOBS, {
                providerId: matches?.params?.providerId
            }),
            title: "JOBS",
            count: "2",
            matchUrl: "/provider-management/:providerId/jobs",
            key: "06"
        },
        {
            path: getParamsAttachedRoute(APP_ROUTES.PROVIDER_DETAILS_PROMOTIONS, {
                providerId: matches?.params?.providerId
            }),
            title: "PROMOTIONS",
            count: "2",
            matchUrl: "/provider-management/:providerId/promotions",
            key: "07"
        },
        {
            path: getParamsAttachedRoute(APP_ROUTES.PROVIDER_DETAILS_RATANDREVIEWS, {
                providerId: matches?.params?.providerId
            }),
            title: "RATING AND REVIEWS",
            count: "2",
            matchUrl: "/provider-management/:providerId/rating-and-reviews",
            key: "08"
        },

        {
            path: getParamsAttachedRoute(APP_ROUTES.PROVIDER_MANAGEMENT_UPDATE, {
                providerId: matches?.params?.providerId
            }),
            title: "UPDATE",
            count: "2",
            matchUrl: "/contents/update/:providerId",
            key: "09"
        },

        {
            path: APP_ROUTES.SEEKER_MANAGEMENT,
            title: "SEEKER MANAGEMENT",
            count: "1",
            matchUrl: "/seeker-management",
            key: "10"
        },
        {
            path: getParamsAttachedRoute(APP_ROUTES.SEEKER_PROFILE_DETAILS, {
                seekerId: matches?.params?.seekerId
            }),
            title: "PROFILE",
            count: "2",
            matchUrl: "/seeker-management/:seekerId/details",
            key: "11"
        },
        {
            path: getParamsAttachedRoute(APP_ROUTES.SEEKER_DETAILS_CARE_PROFILE, {
                seekerId: matches?.params?.seekerId
            }),
            title: "CARE PROFILE",
            count: "2",
            matchUrl: "/seeker-management/:seekerId/care-profile",
            key: "12"
        },
        {
            path: getParamsAttachedRoute(APP_ROUTES.SEEKER_DETAILS_JOBS, {
                seekerId: matches?.params?.seekerId
            }),
            title: "JOBS",
            count: "2",
            matchUrl: "/seeker-management/:seekerId/jobs",
            key: "13"
        },
        {
            path: getParamsAttachedRoute(APP_ROUTES.SEEKER_DETAILS_RATANDREVIEWS, {
                seekerId: matches?.params?.seekerId
            }),
            title: "RATING AND REVIEWS",
            count: "2",
            matchUrl: "/seeker-management/:seekerId/rating-and-reviews",
            key: "14"
        },
        {
            path: APP_ROUTES.CATEGORY_MANAGEMENT,
            title: "CATEGORY MANAGEMENT",
            count: "1",
            matchUrl: "/category-management",
            key: "15"
        },
        {
            path: getParamsAttachedRoute(APP_ROUTES.CATEGORY_MANAGEMENT_DETAILS, {
                categoryId: matches?.params?.categoryId
            }),
            title: "DETAILS",
            count: "2",
            matchUrl: "/category-management/:categoryId",
            key: "16"
        },
        //JOB LISTINGS
        {
            path: APP_ROUTES.JOB_LISTING,
            title: "JOB LISTING",
            count: "1",
            matchUrl: "/jobs",
            key: "17"
        },

        {
            path: getParamsAttachedRoute(APP_ROUTES.JOB_LISTING_DETAIL, {
                jobId: matches?.params?.jobId
            }),
            title: "DETAILS",
            count: "2",
            matchUrl: "/jobs/:jobId",
            key: "18"
        },
        {
            path: APP_ROUTES.ACCOUNT,
            title: " CHANGE PASSWORD",
            count: "1",
            matchUrl: "/account/update",
            key: "19"
        }
    ];
    const breadcrumbItemsToRender = routeConfigs.filter((el) => pathname.startsWith(el.path));

    return (
        <Row className="breadcrumbs-con">
            <Col xs="12" className="breadcrumbs-col">
                <div className="d-flex align-items-center justify-content-between">
                    <ReactstrapBreadcrumb>
                        {breadcrumbItemsToRender.map((el, idx) => {
                            const isActive =
                                pathname === el.path || pathNameArr.includes(el.matchUrl);

                            return (
                                <BreadcrumbItem
                                    key={el.key}
                                    active={isActive}
                                    className={
                                        isActive
                                            ? "breadcrumb-typo-bold breadcrumb-max-width"
                                            : "breadcrumb-max-width breadcrumb-typo-bold "
                                    }
                                >
                                    {isActive || el.count === "2" ? (
                                        <span className="breadcrumb-typo-bold">{el.title} </span>
                                    ) : (
                                        <Link
                                            to={el.path}
                                            className="breadcrumb-typo breadcrumb-max-width"
                                        >
                                            {el.title}
                                        </Link>
                                    )}
                                </BreadcrumbItem>
                            );
                        })}
                    </ReactstrapBreadcrumb>
                </div>
            </Col>
        </Row>
    );
};

Breadcrumb.propTypes = {
    breadcrumbItems: PropTypes.array,
    title: PropTypes.string
};

export default Breadcrumb;
