// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.analytics-loader-con {
  height: 90px !important;
  margin-bottom: 24px;
}

.graph-loader {
  height: 335px !important;
}

.roundsection-loader {
  height: 233px;
  background-color: white;
  box-shadow: 0px 4px 18px 0px rgba(18, 38, 63, 0.07);
  padding: 1.25rem;
}

.fixed-height {
  height: 184px !important;
}

.analytics-image-container {
  background-color: #f3f3f3;
  width: 49px;
  height: 49px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Dashboard/Dashboard.scss"],"names":[],"mappings":"AAAA;EACI,uBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,wBAAA;AACJ;;AAEA;EACI,aAAA;EACA,uBAAA;EACA,mDAAA;EACA,gBAAA;AACJ;;AACA;EACI,wBAAA;AAEJ;;AACA;EACI,yBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;AAEJ","sourcesContent":[".analytics-loader-con {\n    height: 90px !important;\n    margin-bottom: 24px;\n}\n\n.graph-loader {\n    height: 335px !important;\n}\n\n.roundsection-loader {\n    height: 233px;\n    background-color: white;\n    box-shadow: 0px 4px 18px 0px rgba(18, 38, 63, 0.07);\n    padding: 1.25rem;\n}\n.fixed-height {\n    height: 184px !important;\n}\n\n.analytics-image-container {\n    background-color: #f3f3f3;\n    width: 49px;\n    height: 49px;\n    border-radius: 50%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    margin-right: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
