import React, { useState } from "react";
import { Container, Row, Col } from "reactstrap";
import { useParams } from "react-router-dom";

import { Pagination } from "../../components/Pagination";
import { DataTable } from "../../components/DataTable";
import { CategoryStatus } from "../../constants";
import suspendModalImg from "../../assets/images/app/common/modal-suspend-img.svg";
import { GetCategoryById, UpdateSubCategoryById } from "../../api/api.service";
import { MODULE_KEYS, getKey } from "../../api/api.keys";
import { useRequestProcessor } from "../../hooks/useRequestProcessors";
import { useLocalPaginatedRequest } from "../../hooks/useLocalPaginatedRequest";
import { SwitchInput } from "../../components/Input";
import { SUCCESS, showToast } from "../../utils/toastUtils";
import { ConfirmationModal } from "../../components/CustomModal";
import { getStatus, findStatusChecked, handleAdModal } from "./utils";
import {
    formatKey,
    HOME_MAINTENANCE,
    renderCategoryName,
    truncateString
} from "../../utils/commonUtils";
import { HeaderBackButton } from "../../components/HeaderBackButton";

const MODAL_CONTENT_CONFIG = {
    [CategoryStatus.INACTIVE]: {
        modalTitle: "Are you sure you want to deactivate this Subcategory?",
        yesButtonText: "Yes",
        noButtonText: "Cancel",
        imageSrc: suspendModalImg
    },
    [CategoryStatus.ACTIVE]: {
        modalTitle: "Are you sure you want to activate this Subcategory?",
        yesButtonText: "Yes",
        noButtonText: "Cancel",
        imageSrc: suspendModalImg
    }
};

const COLUMNS = {
    SUB_CATEGORY_ID: "SubCategory Id",
    SUB_CATEGORY_NAME: "SubCategory Name",
    ACTIVE_STATUS_TOGGLE: "Active Status"
};

const recordUpdateSuccessMessage = "Status has been updated successfully";

const Detail = () => {
    const { categoryId } = useParams();
    const [modalConfig, setModalConfig] = useState(null);
    const [isCategoryStatusUpdating, setIsCategoryStatusUpdating] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState({ status: "", id: "" });

    const {
        isFetching,
        page,
        handlePageClick,
        total,
        data,
        searchText,
        limit,
        handleSearchChange,
        handleSortingChange,
        refetch
    } = useLocalPaginatedRequest({
        key: getKey([MODULE_KEYS.SUB_CATEGORIES_MANAGEMENT], { categoryId }),
        requestFn: GetCategoryById,
        id: categoryId,
        params: {}
    });

    let { useMutate } = useRequestProcessor();
    let { mutate } = useMutate("", getKey(), (data) =>
        UpdateSubCategoryById({ id: categoryId, subTypeId: selectedRecord?.id, bodyData: data })
    );

    const handleActionInit = (id, status) => {
        setSelectedRecord({ id, status });
        let statusToSet = getStatus(status);
        setModalConfig(MODAL_CONTENT_CONFIG[statusToSet]);
    };

    const handleActionCancellation = () => {
        setModalConfig(null);
    };

    // handling advertisement status update

    const handleCategoryStatusUpdate = () => {
        setIsCategoryStatusUpdating(true);
        let statusToSet = getStatus(selectedRecord.status);
        const bodyData = { status: statusToSet };
        // Api call here
        mutate(bodyData, {
            onSuccess: async (res) => {
                setIsCategoryStatusUpdating(false);
                showToast(SUCCESS, recordUpdateSuccessMessage);

                setModalConfig(null);
                refetch();
            },
            onError: (err) => {
                setIsCategoryStatusUpdating(false);
            }
        });
    };

    return (
        <div className="page-content">
            <div className="d-flex align-items-center justify-content-between ">
                {data?.name ? (
                    <div className="headeing-text">
                        {formatKey(
                            data?.name == HOME_MAINTENANCE
                                ? renderCategoryName(data?.name)
                                : data?.name
                        )}
                    </div>
                ) : (
                    "Loading..."
                )}
            </div>
            <Container fluid>
                <DataTable
                    tableContainerClass="mt-3"
                    loading={isFetching}
                    data={data?.categorySubType}
                    config={[
                        {
                            title: COLUMNS.SUB_CATEGORY_ID,
                            render: (data) => <div>{data.id || "-"}</div>
                        },
                        {
                            title: COLUMNS.SUB_CATEGORY_NAME,
                            render: (data) => formatKey(data.name)
                        },
                        {
                            title: COLUMNS.ACTIVE_STATUS_TOGGLE,
                            render: (data) =>
                                (
                                    <div className="d-flex">
                                        <SwitchInput
                                            size="md"
                                            isChecked={findStatusChecked(data?.status)}
                                            onClick={() => handleActionInit(data?.id, data?.status)}
                                        />
                                    </div>
                                ) || "-"
                        }
                    ]}
                />
                <Pagination
                    rowLimit={limit}
                    onPageClick={handlePageClick}
                    currentPage={page}
                    totalData={total}
                    handleChange={(e) => handleSortingChange(e.target.value)}
                />
            </Container>
            <ConfirmationModal
                size="md"
                hasCustomConfig
                isOpen={modalConfig}
                customImageSrc={modalConfig?.imageSrc}
                customTitle={modalConfig?.modalTitle}
                customYesBtnText={modalConfig?.yesButtonText}
                customNoBtnText={modalConfig?.noButtonText}
                onYes={handleCategoryStatusUpdate}
                onNo={handleActionCancellation}
                yesLoading={isCategoryStatusUpdating}
            />
        </div>
    );
};

export default Detail;
