import React, { useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Col, Label, Row } from "reactstrap";

import { RoutingTabs } from "../../components/RoutingTabs";
import { APP_ROUTES } from "../../helpers/routeHelpers";
import { SwitchInput } from "../../components/Input";
import { CustomButton } from "../../components/CustomButton";

import suspendModalImg from "../../assets/images/app/common/modal-suspend-img.svg";
import deleteModalImg from "../../assets/images/app/common/modal-delete-img.svg";

import { ConfirmationModal } from "../../components/CustomModal";
import { useRequestProcessor } from "../../hooks/useRequestProcessors";
import { getKey, MODULE_KEYS } from "../../api/api.keys";
import { DeleteUserById, GetSeekerById, UpdateUserById } from "../../api/api.service";
import { showToast, SUCCESS } from "../../utils/toastUtils";
import { HeaderBackButton } from "../../components/HeaderBackButton";

import DetailProfile from "./Detail_Profile";
import DetailJob from "./Detail_Job";
import DetailRatingAndReviews from "./Detail_RatingAndReviews";
import DetailCareProfile from "./Detail_CareProfile";
import { hasData } from "../../utils/commonUtils";
import { LoaderFallbackUI } from "../../components/LoaderFallbackUI";
import { JobsStatus } from "../../constants";

const ACTIVE = "ACTIVE";
const INACTIVE = "INACTIVE";
const SEEKER = "SEEKER";

const MODAL_CONTENT_CONFIG = {
    [ACTIVE]: {
        modalTitle: "Are you sure you want to activate this seeker?",
        yesButtonText: "Yes",
        noButtonText: "Cancel",
        imageSrc: suspendModalImg
    },
    [INACTIVE]: {
        modalTitle: "Are you sure you want to deactivate this seeker?",
        yesButtonText: "Yes",
        noButtonText: "Cancel",
        imageSrc: suspendModalImg
    },
    DELETE: {
        modalTitle: "Are you sure you want to delete this seeker?",
        yesButtonText: "Yes",
        noButtonText: "Cancel",
        imageSrc: deleteModalImg
    }
};

const Details = ({}) => {
    const history = useHistory();
    const { seekerId } = useParams();
    const [seekerStatus, setSeekerStatus] = useState(null);
    const [modalConfig, setModalConfig] = useState(null);
    const { useFetch, useMutate } = useRequestProcessor();

    const { data, isLoading, isError, isFetching } = useFetch(
        getKey([MODULE_KEYS.SEEKER_PROFILE], { seekerId }),
        async ({ signal }) => await GetSeekerById({ Id: seekerId, params: {}, signal }),
        {
            onSuccess: (res) => {
                setSeekerStatus(res?.seeker?.status);
            }
        }
    );

    const { mutate: updateseekerStatus, isLoading: isStatusUpdate } = useMutate(
        "",
        getKey(),
        (data) => UpdateUserById({ Id: seekerId, bodyData: data })
    );

    const { mutate: deleteSeekerMutate, isLoading: isDeleted } = useMutate("", getKey(), (data) =>
        DeleteUserById({ Id: seekerId })
    );

    const handleToggleStatus = () => {
        const nextStatus = seekerStatus === ACTIVE ? INACTIVE : ACTIVE;
        setModalConfig(MODAL_CONTENT_CONFIG[nextStatus]);
    };

    const handleDelete = () => {
        setModalConfig(MODAL_CONTENT_CONFIG.DELETE);
    };

    const handleActionConfirmation = () => {
        if (modalConfig === MODAL_CONTENT_CONFIG.DELETE) {
            deleteSeekerMutate(
                {},
                {
                    onSuccess: () => {
                        showToast(SUCCESS, "Seeker has been deleted successfully");
                        history.push(APP_ROUTES.SEEKER_MANAGEMENT);
                    },
                    onError: () => {}
                }
            );
        } else {
            const nextStatus = seekerStatus === ACTIVE ? INACTIVE : ACTIVE;
            updateseekerStatus(
                { type: SEEKER, status: nextStatus },
                {
                    onSuccess: () => {
                        showToast(SUCCESS, "Status has been updated successfully");
                        setSeekerStatus(nextStatus);
                    },
                    onError: () => {}
                }
            );
        }

        setModalConfig(null);
    };

    const handleActionCancellation = () => {
        setModalConfig(null);
    };

    let CONFIG = {
        param: { seekerId },
        tabsConfig: [
            {
                title: "Profile",
                id: "1",
                defaultActive: true,
                path: APP_ROUTES.SEEKER_PROFILE_DETAILS,
                component: <DetailProfile data={data} />
            },
            {
                title: "Care Profiles",
                id: "2",
                defaultActive: false,
                path: APP_ROUTES.SEEKER_DETAILS_CARE_PROFILE,
                component: <DetailCareProfile />
            },
            {
                title: "Jobs",
                id: "3",
                defaultActive: false,
                path: APP_ROUTES.SEEKER_DETAILS_JOBS,
                component: <DetailJob />
            },

            {
                title: "Rating and Reviews",
                id: "4",
                defaultActive: false,
                path: APP_ROUTES.SEEKER_DETAILS_RATANDREVIEWS,
                component: <DetailRatingAndReviews ratingData={data?.rating} />
            }
        ]
    };

    if (isFetching || isLoading || !hasData(data)) {
        return (
            <div style={{ height: "70vh" }}>
                <LoaderFallbackUI isLoader={isFetching} className={"h-100"} />
            </div>
        );
    }

    return (
        <>
            <div className="page-content">
                <Row className="d-flex align-items-center">
                    <Col sm="12" md="2" lg="2"></Col>
                    <Col sm="12" md="3" lg="3"></Col>
                    <Col sm="12" md="7" lg="7">
                        <div className="user-management-details-option-container ">
                            <div className=" d-flex align-items-center me-3">
                                <Label className="mb-0 me-3">Account Status:</Label>
                                <SwitchInput
                                    size="lg"
                                    isChecked={seekerStatus === ACTIVE}
                                    onClick={handleToggleStatus}
                                />
                            </div>

                            <div className="user-management-delete-container ">
                                <CustomButton
                                    disabled={
                                        !(
                                            seekerStatus == JobsStatus.ACTIVE ||
                                            seekerStatus == JobsStatus.IN_PROGRESS
                                        )
                                    }
                                    title={"Delete"}
                                    className={`custom-btn-action-red`}
                                    onClick={handleDelete}
                                    type="button"
                                />
                            </div>
                        </div>
                    </Col>
                    <RoutingTabs
                        config={CONFIG}
                        isPills
                        isTabs={false}
                        parentRoute={APP_ROUTES.SEEKER_MANAGEMENT_DETAILS}
                    />
                </Row>
            </div>

            <ConfirmationModal
                size="md"
                hasCustomConfig
                isOpen={modalConfig}
                customImageSrc={modalConfig?.imageSrc}
                customTitle={modalConfig?.modalTitle}
                customYesBtnText={modalConfig?.yesButtonText}
                customNoBtnText={modalConfig?.noButtonText}
                onYes={handleActionConfirmation}
                onNo={handleActionCancellation}
                yesLoading={isStatusUpdate || isDeleted}
            />
        </>
    );
};

export default Details;
