import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Label } from "reactstrap";
import { Redirect } from "react-router-dom";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { withRouter, Link } from "react-router-dom";

import { loginFormValidationSchema, initialValues } from "./FormValidations";
import { TextInput, PasswordInput } from "../../components/Input";
import { CustomButton } from "../../components/CustomButton";
import { APP_ROUTES } from "../../helpers/routeHelpers";
import { login } from "../../store/actions";
import AuthWrapper from "./AuthWrapper";
import PageTitles from "../../constants/PageTitles";
import Headings from "./Headings";
import { ERROR, SUCCESS, showToast } from "../../utils/toastUtils";

const Login = ({}) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    let isAuthenticated = useSelector((state) => state.userAuth.isAuthenticated);

    const formik = useFormik({
        // enableReinitialize: true,
        initialValues: initialValues.loginForm,
        validationSchema: loginFormValidationSchema,
        onSubmit: (values) => {
            handleFormSubmit(values);
        }
    });

    const handleFormSubmit = async (values) => {
        const { email, password } = values;
        try {
            setIsLoading(true);
            await dispatch(
                login({
                    email: email,
                    password: password
                })
            );
            showToast(SUCCESS, "Login successfully !");
        } catch (err) {
        } finally {
            setIsLoading(false);
        }
    };

    if (isAuthenticated) {
        return <Redirect to={APP_ROUTES.DASHBOARD} />;
    }

    return (
        <>
            <Helmet>
                <title>{PageTitles.LOGIN}</title>
            </Helmet>
            <AuthWrapper>
                <div className="w-100">
                    <div className="w-100 d-flex align-items-center justify-content-center">
                        <Headings
                            title="WELCOME BACK"
                            subText="Access your admin dashboard and manage the Our Village platform"
                        />
                    </div>
                    <div className="w-100 d-flex align-items-center justify-content-center mt-4">
                        <form className="w-50" onSubmit={formik.handleSubmit}>
                            <Label>Email</Label>
                            <div className="mb-2">
                                <TextInput
                                    placeholder="Enter Email"
                                    name={"email"}
                                    type="email"
                                    className="hide-default-icon"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.email}
                                    autoFocus
                                />
                                {formik.touched.email && formik.errors.email ? (
                                    <span className="error">{formik.errors.email}</span>
                                ) : null}
                            </div>

                            <Label>Password</Label>
                            <div className="mb-2">
                                <PasswordInput
                                    placeholder="Enter Password"
                                    className="hide-default-icon"
                                    name="password"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.password}
                                />
                                {formik.touched.password && formik.errors.password ? (
                                    <span className="error">{formik.errors.password}</span>
                                ) : null}
                            </div>
                            <div className="d-flex align-items-center justify-content-start my-2">
                                <Link to={APP_ROUTES.FORGET_PASSWORD}>
                                    <p className="font-clr-theme-secondary text-decoration-underline">
                                        {" "}
                                        Forgot your password ?{" "}
                                    </p>
                                </Link>
                            </div>

                            <CustomButton
                                loading={isLoading}
                                type="submit"
                                color="primary"
                                title="LOGIN"
                                className="w-100 mt-3"
                            />
                        </form>
                    </div>
                </div>
            </AuthWrapper>
        </>
    );
};

export default withRouter(Login);
