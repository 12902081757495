// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.otp-input {
  width: 15%;
  height: 15%;
  margin: 5px;
  padding: 0.5rem 1rem;
  border-radius: 2px;
  font-size: 1.01562rem;
  text-align: center;
  border-width: 1px;
  border-style: solid;
  text-transform: uppercase;
  border-color: #ced4da;
  background-color: white;
}`, "",{"version":3,"sources":["webpack://./src/components/CodeInput/CodeInput.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;EACA,WAAA;EACA,WAAA;EACA,oBAAA;EACA,kBAAA;EACA,qBAAA;EACA,kBAAA;EACA,iBAAA;EACA,mBAAA;EACA,yBAAA;EACA,qBAAA;EACA,uBAAA;AACJ","sourcesContent":[".otp-input {\n    width: 15%;\n    height: 15%;\n    margin: 5px;\n    padding: 0.5rem 1rem;\n    border-radius: 2px;\n    font-size: 1.01562rem;\n    text-align: center;\n    border-width: 1px;\n    border-style: solid;\n    text-transform: uppercase;\n    border-color: #ced4da;\n    background-color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
