import React from "react";
import PropTypes from "prop-types";
import { Col, Row } from "reactstrap";

const LabeledDataItem = ({
    title = "-",
    desc = "-",
    includeHR = false,
    titleClass = "clr-theme-primary",
    descClass = "",
    rowClass = "",
    titleColProps = { xs: 4 },
    descColProps = { xs: 8 },
    hrClass = ""
}) => {
    const renderHorizontalRule = () => {
        if (!includeHR) return null;
        return (
            <Col xs={12}>
                <hr className={hrClass} />
            </Col>
        );
    };

    return (
        <>
            <Row className={rowClass}>
                <Col {...titleColProps}>
                    <strong className={titleClass}>{title}</strong>
                </Col>
                <Col {...descColProps} className={descClass}>
                    {desc}
                </Col>
            </Row>
            {renderHorizontalRule()}
        </>
    );
};

LabeledDataItem.propTypes = {
    title: PropTypes.string,
    desc: PropTypes.string,
    includeHR: PropTypes.bool,
    titleClass: PropTypes.string,
    descClass: PropTypes.string,
    rowClass: PropTypes.string,
    titleColProps: PropTypes.object,
    descColProps: PropTypes.object,
    hrClass: PropTypes.string
};

LabeledDataItem.defaultProps = {
    title: "-",
    desc: "-",
    includeHR: false,
    titleClass: "clr-theme-primary",
    descClass: "",
    rowClass: "",
    titleColProps: { xs: 4 },
    descColProps: { xs: 8 },
    hrClass: ""
};

export default LabeledDataItem;
