// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.preview-container {
  width: 100px;
  height: 100px;
  position: relative;
  padding: 5px;
}
.preview-container .cross-button {
  width: 15px;
  height: 15px;
  background-color: white;
  position: absolute;
  right: 10px;
  top: 10px;
  border-radius: 50px;
}
.preview-container .edit-button {
  width: 35px;
  height: 35px;
  position: absolute;
  right: -1px;
  bottom: -2px;
}
.preview-container .cursur--pointer {
  cursor: pointer;
}
.preview-container .preview-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
  cursor: pointer;
}

.uploaded-image {
  width: 100%;
  height: 120px;
  object-fit: cover;
}

.selected-preview-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/ImageBox/Style.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,aAAA;EACA,kBAAA;EACA,YAAA;AACJ;AACI;EACI,WAAA;EACA,YAAA;EACA,uBAAA;EACA,kBAAA;EACA,WAAA;EACA,SAAA;EACA,mBAAA;AACR;AAEI;EACI,WAAA;EACA,YAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;AAAR;AAEI;EACI,eAAA;AAAR;AAGI;EACI,WAAA;EACA,YAAA;EACA,iBAAA;EACA,mBAAA;EACA,eAAA;AADR;;AAIA;EACI,WAAA;EACA,aAAA;EACA,iBAAA;AADJ;;AAGA;EACI,WAAA;EACA,YAAA;EACA,iBAAA;EACA,mBAAA;AAAJ","sourcesContent":[".preview-container {\n    width: 100px;\n    height: 100px;\n    position: relative;\n    padding: 5px;\n\n    & .cross-button {\n        width: 15px;\n        height: 15px;\n        background-color: white;\n        position: absolute;\n        right: 10px;\n        top: 10px;\n        border-radius: 50px;\n    }\n\n    & .edit-button {\n        width: 35px;\n        height: 35px;\n        position: absolute;\n        right: -1px;\n        bottom: -2px;\n    }\n    .cursur--pointer {\n        cursor: pointer;\n    }\n\n    & .preview-image {\n        width: 100%;\n        height: 100%;\n        object-fit: cover;\n        border-radius: 20px;\n        cursor: pointer;\n    }\n}\n.uploaded-image {\n    width: 100%;\n    height: 120px;\n    object-fit: cover;\n}\n.selected-preview-image {\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n    border-radius: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
