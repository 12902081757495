import { Dashboard } from "../pages/Dashboard";
import { UserManagement } from "../pages/UserManagement";

import {
    ProviderManagement,
    Details as ProviderDetails,
    DetailProfile,
    CategoriesAndAvailibility,
    DetailJob,
    DetailPromotions,
    DetailRatingAndReviews
} from "../pages/ProviderManagement";

import {
    SeekerManagement,
    Details as SeekerDetails,
    DetailProfile as SeekerProfile,
    DetailJob as SeekerJob,
    DetailRatingAndReviews as SeekerRatingAndReviews,
    DetailCareProfile as SeekerDetailCareProfile
} from "../pages/SeekerManagement";

import { CategoryManagement, Detail as CategoryDetail } from "../pages/CategoryManagement";

import { JobListing, Details as JobDetails } from "../pages/JobListing";

import { Account } from "../pages/Account";
import { APP_ROUTES } from "../helpers/routeHelpers";
import { Roles } from "../constants";

let { Admin } = Roles;

const authProtectedRoutes = [
    // Dashboard
    {
        path: APP_ROUTES.DASHBOARD,
        component: Dashboard,
        permissions: [Admin],
        exact: true
    },
    // User Management

    {
        path: APP_ROUTES.USER_MANAGEMENT,
        component: UserManagement,
        permissions: [Admin],
        exact: true
    },

    // Provider Management

    {
        path: APP_ROUTES.PROVIDER_MANAGEMENT,
        component: ProviderManagement,
        permissions: [Admin],
        exact: true
    },

    {
        path: APP_ROUTES.PROVIDER_MANAGEMENT_DETAILS,
        component: ProviderDetails,
        permissions: [Admin],
        exact: false
    },
    {
        path: APP_ROUTES.PROVIDER_DETAILS,
        component: DetailProfile,
        permissions: [Admin],
        exact: true
    },
    {
        path: APP_ROUTES.PROVIDER_DETAILS_CATANDAVAIL,
        component: CategoriesAndAvailibility,
        permissions: [Admin],
        exact: true
    },
    {
        path: APP_ROUTES.PROVIDER_DETAILS_JOBS,
        component: DetailJob,
        permissions: [Admin],
        exact: true
    },
    {
        path: APP_ROUTES.PROVIDER_DETAILS_PROMOTIONS,
        component: DetailPromotions,
        permissions: [Admin],
        exact: true
    },
    {
        path: APP_ROUTES.PROVIDER_DETAILS_RATANDREVIEWS,
        component: DetailRatingAndReviews,
        permissions: [Admin],
        exact: true
    },

    //Seeker Management
    {
        path: APP_ROUTES.SEEKER_MANAGEMENT,
        component: SeekerManagement,
        permissions: [Admin],
        exact: true
    },
    {
        path: APP_ROUTES.SEEKER_MANAGEMENT_DETAILS,
        component: SeekerDetails,
        permissions: [Admin],
        exact: false
    },
    {
        path: APP_ROUTES.SEEKER_PROFILE_DETAILS,
        component: SeekerProfile,
        permissions: [Admin],
        exact: true
    },

    {
        path: APP_ROUTES.SEEKER_DETAILS_JOBS,
        component: SeekerJob,
        permissions: [Admin],
        exact: true
    },

    {
        path: APP_ROUTES.SEEKER_DETAILS_RATANDREVIEWS,
        component: SeekerRatingAndReviews,
        permissions: [Admin],
        exact: true
    },
    {
        path: APP_ROUTES.SEEKER_DETAILS_CARE_PROFILE,
        component: SeekerDetailCareProfile,
        permissions: [Admin],
        exact: true
    },

    //Category Management

    {
        path: APP_ROUTES.CATEGORY_MANAGEMENT,
        component: CategoryManagement,
        permissions: [Admin],
        exact: true
    },
    {
        path: APP_ROUTES.CATEGORY_MANAGEMENT_DETAILS,
        component: CategoryDetail,
        permissions: [Admin],
        exact: true
    },

    //Job Listing
    {
        path: APP_ROUTES.JOB_LISTING,
        component: JobListing,
        permissions: [Admin],
        exact: true
    },
    {
        path: APP_ROUTES.JOB_LISTING_DETAIL,
        component: JobDetails,
        permissions: [Admin],
        exact: true
    },

    //Account

    {
        path: APP_ROUTES.ACCOUNT,
        component: Account,
        permissions: [Admin],
        exact: false
    }
];

export { authProtectedRoutes };
