import React from "react";

const Headings = ({ title = "Title", subText = "Subtext" }) => {
    return (
        <div className="d-flex flex-column align-items-center ">
            <h1 className="clr-theme-primary fw-bolder m-0 p-0 text-center">{title}</h1>
            <p className="m-0 p-0 text-center">{subText}</p>
        </div>
    );
};

export default Headings;
