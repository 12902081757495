// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.headeing-text {
  font-size: 1rem;
  color: #000000;
  font-weight: 600;
  text-decoration: underline;
  margin-left: 10px;
}`, "",{"version":3,"sources":["webpack://./src/pages/CategoryManagement/Style.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,cAAA;EACA,gBAAA;EACA,0BAAA;EACA,iBAAA;AACJ","sourcesContent":[".headeing-text {\n    font-size: 1rem;\n    color: #000000;\n    font-weight: 600;\n    text-decoration: underline;\n    margin-left: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
