import { CategoryStatus } from "../../constants";

import suspendModalImg from "../../assets/images/app/common/modal-suspend-img.svg";

export const MODAL_CONTENT_CONFIG = {
    [CategoryStatus.INACTIVE]: {
        modalTitle: "Are you sure you want to deactivate this category?",
        yesButtonText: "Yes",
        noButtonText: "Cancel",
        imageSrc: suspendModalImg
    },
    [CategoryStatus.ACTIVE]: {
        modalTitle: "Are you sure you want to activate this category?",
        yesButtonText: "Yes",
        noButtonText: "Cancel",
        imageSrc: suspendModalImg
    }
};
