// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.body-search .form-control {
  width: 300px;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding-left: 40px;
  padding-right: 20px;
  outline: none;
  font-weight: 500;
}
.body-search span {
  position: absolute;
  z-index: 3;
  font-size: 20px;
  line-height: 38px;
  left: 13px;
  top: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/SearchBox/Style.scss"],"names":[],"mappings":"AAGI;EASE,YAAA;EACA,YAAA;EACA,sBAAA;EACA,kBAAA;EACA,kBAAA;EACA,mBAAA;EACA,aAAA;EACA,gBAAA;AAVN;AAYI;EACE,kBAAA;EACA,UAAA;EACA,eAAA;EACA,iBAAA;EACA,UAAA;EACA,MAAA;AAVN","sourcesContent":["\n.body-search {\n  \n    .form-control {\n      // Old Searchbar CSS\n      // border: none;\n      // height: 38px;\n      // padding-left: 40px;\n      // padding-right: 20px;\n      // box-shadow: none;\n      // border-radius: 4px;\n\n      width: 300px;\n      height: 40px; \n      border: 1px solid #ccc; \n      border-radius: 4px; \n      padding-left: 40px;\n      padding-right: 20px;\n      outline: none; \n      font-weight: 500;\n    }\n    span {\n      position: absolute;\n      z-index: 3;\n      font-size: 20px;\n      line-height: 38px;\n      left: 13px;\n      top: 0;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
