// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btns-main-con {
  width: 75%;
}

.custom-close-btn-popup {
  border: none;
  background: transparent;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1050;
}

/* Hides the close button */
.modal-header .btn-close {
  display: none !important;
}

@media (max-width: 500px) {
  .btns-main-con {
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/CustomModal/Style.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;AACJ;;AAEA;EACI,YAAA;EACA,uBAAA;EACA,kBAAA;EACA,SAAA;EACA,WAAA;EACA,aAAA;AACJ;;AACA,2BAAA;AACA;EACI,wBAAA;AAEJ;;AACA;EACI;IACI,WAAA;EAEN;AACF","sourcesContent":[".btns-main-con {\n    width: 75%;\n}\n\n.custom-close-btn-popup {\n    border: none;\n    background: transparent;\n    position: absolute;\n    top: 10px;\n    right: 10px;\n    z-index: 1050;\n}\n/* Hides the close button */\n.modal-header .btn-close {\n    display: none !important;\n}\n\n@media (max-width: 500px) {\n    .btns-main-con {\n        width: 100%;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
