import "./Style.scss";
import SeekerManagement from "./SeekerManagement";
import DetailProfile from "./Detail_Profile";
import Details from "./Details";
import DetailJob from "./Detail_Job";
import DetailRatingAndReviews from "./Detail_RatingAndReviews";
import DetailCareProfile from "./Detail_CareProfile";

export {
    SeekerManagement,
    DetailProfile,
    DetailCareProfile,
    Details,
    DetailJob,
    DetailRatingAndReviews
};
