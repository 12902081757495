import moment from "moment";

export const dateTimeFormat = {
    monthLetterFormat: "DD MMM, YYYY",
    monthYearLetterFormat: "MMMM YYYY",
    appDateTimeFormat: "DD/MM/YYYY - HH:mm ",
    appMonthDateTimeFormat: "MM/DD/YY - HH:mm ",
    appDateFormat: "MM/DD/YY",
    apiDateTimeFormat: "YYYY-MM-DD HH:mm:ss",
    apiDateFormat: "YYYY-MM-DD",
    gmtDateTimeFormat: "YYYY-MM-DD HH:mm",
    creationDateFormat: "DD MMMM, YYYY",
    apiTimeFormat: "HH:mm:ss",
    appTimeFormat: "HH:mm",
    payoutDateQuery: "YYYY-MM",
    timeFormat: "LT",
    dateWithTimeFormat: "MMM  Do, h:mm A",
    dateWithYearTimeFormat: "MMM  Do YYYY, h:mm A"
};

export const convertDateTime = ({ date, dateOnly = false, customFormat = null }) => {
    if (!date) return "";
    const format = customFormat
        ? customFormat
        : dateOnly
        ? dateTimeFormat.apiDateFormat
        : dateTimeFormat.apiDateTimeFormat;

    return moment(date).format(format);
};

export const convertTime = ({ tz, time, format }) => {
    // Parse the date with the format 'YYYY-MM-DD'
    const parsedTime = moment(time, "YYYY-MM-DD");

    if (!parsedTime.isValid()) {
        console.error("Invalid time format:", time);
        return "Invalid Date";
    }

    // Handle the timezone conversion
    if (tz === "GMT_TIME") {
        return parsedTime.utc().format(format);
    } else if (tz === "LOCAL_TIME") {
        return parsedTime.local().format(format);
    }

    return "Unknown Timezone";
};

export const convertDateTimeGMTFormat = (date) => {
    const startOf = () => {
        return moment(date).startOf("day").format(dateTimeFormat.gmtDateTimeFormat);
    };
    const endOf = () => {
        return moment(date).endOf("day").format(dateTimeFormat.gmtDateTimeFormat);
    };
    return {
        startOf,
        endOf
    };
};

export const convertDateToInvoicePattern = (date) => {
    const getPreviousMonthDate = () => {
        return moment(date).subtract(1, "month").format("LL");
    };
    const getPreviousDayDate = () => {
        return moment(date).subtract(1, "day").format("LL");
    };
    return {
        getPreviousMonthDate,
        getPreviousDayDate
    };
};

export const getTimeWithSeconds = (time, format, requiredFormat) => {
    return moment(time, format).format(requiredFormat);
};

export const getNearestTimeStampStr = (date) => {
    let isDaySame = moment().isSame(date, "day");
    let isYearSame = moment().isSame(date, "year");
    if (isDaySame) {
        return moment(date).format(dateTimeFormat.timeFormat);
    } else if (!isDaySame && isYearSame) {
        return moment(date).format(dateTimeFormat.dateWithTimeFormat);
    } else return moment(date).format(dateTimeFormat.dateWithYearTimeFormat);
};

// Utility function to format a date into a full month name (e.g., "September").
// The date is formatted according to the "en-US" locale to ensure consistency.
export const formatMonth = (date) => {
    const options = { month: "long" };
    return new Date(date).toLocaleDateString("en-US", options);
};

// Utility function to format a date into a short date string (e.g., "17th Sep").
// The date is formatted according to the "en-US" locale for consistency.
export const formatDate = (date) => {
    return new Date(date).toLocaleDateString("en-US", { day: "numeric", month: "short" });
};

// Utility function to format time into a human-readable format (e.g., "6pm").
export const formatTime = (time) => {
    const options = { hour: "numeric", minute: "numeric", hour12: true };
    return new Date(time).toLocaleTimeString("en-US", options);
};

// Utility function to transform provider availability data into a structured format
// that groups available dates by month. This function takes an array of availability
// objects and returns an object containing:
// - `months`: An array of unique month names derived from the availability dates.
// - `dates`: An object where each key is a month name and the value is an array of formatted dates.
// - `timeRange`: An object where each key is a month name and the value is the time range (e.g., "6pm to 10pm").
export const transformAvailabilityData = (availability) => {
    // Extract unique month names from the availability data
    const months = [...new Set(availability?.map((item) => formatMonth(item.date)))];

    // Group dates by month and extract the time range
    const dates = months.reduce((acc, month) => {
        acc[month] = availability
            .filter((item) => formatMonth(item.date) === month)
            .map((item) => formatDate(item.date));
        return acc;
    }, {});

    // Extract the start and end time for each month
    const timeRange = months.reduce((acc, month) => {
        const monthAvailability = availability.filter((item) => formatMonth(item.date) === month);
        if (monthAvailability.length > 0) {
            const startTime = formatTime(monthAvailability[0].startTime);
            const endTime = formatTime(monthAvailability[0].endTime);
            acc[month] = `${startTime} to ${endTime}`;
        }
        return acc;
    }, {});

    // Return an object containing the grouped months, dates, and time range
    return { months, dates, timeRange };
};
