import "./Style.scss";
import ProviderManagement from "./ProviderManagement";
import DetailProfile from "./Detail_Profile";
import CategoriesAndAvailibility from "./Detail_CatAvail";
import Details from "./Details";
import DetailJob from "./Detail_Job";
import DetailPromotions from "./Detail_Promotions";
import DetailRatingAndReviews from "./Detail_RatingAndReviews";

export {
    ProviderManagement,
    Details,
    DetailProfile,
    CategoriesAndAvailibility,
    DetailJob,
    DetailPromotions,
    DetailRatingAndReviews
};
