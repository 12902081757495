import React, { useState } from "react";

import { CustomModal } from "../CustomModal";
import ImagePreview from "../ImagePreview/ImagePreview";

const ImageGallery = ({ previousImages = [], isDetailView = true }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    // State to track the selected image index
    const [selectedImageIndex, setSelectedImageIndex] = useState(null);

    // Function to open the modal with the selected image
    const handleImageClick = (idx, item) => {
        setSelectedImageIndex(idx);
        setIsModalOpen(true);
    };

    // Function to close the modal
    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    // Convert previousImages to the format expected by getMediaPath
    const images = previousImages.map((image) => ({
        media: {
            path: image.media.path
        }
    }));

    const sliderSettings = {
        customPaging: (index) => {
            const thumbnailUrl = images[index]?.media?.path;
            console.log("thumbnailUrl", thumbnailUrl, index);
            return (
                <img src={thumbnailUrl} alt={`Thumbnail ${index + 1}`} width="30px" height="30px" />
            );
        },
        dots: true,
        dotsClass: "slick-dots",
        waitForAnimate: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        initialSlide: selectedImageIndex
    };

    return (
        <>
            <div className="d-flex flex-wrap justify-content-start">
                {previousImages?.length > 0 &&
                    previousImages?.map((item, index) => (
                        <div
                            key={index}
                            className="preview-container"
                            onClick={() => handleImageClick(index, item)}
                        >
                            <img
                                src={item.media.path}
                                className="preview-image rounded"
                                alt={`Image ${index + 1}`}
                            />
                        </div>
                    ))}
            </div>

            {isDetailView && (
                <CustomModal
                    isOpen={isModalOpen}
                    modalBodyClasses="d-flex justify-content-center"
                    customButton={true}
                    hideModalHeaderSeparator={true}
                    headerClasses="header-container"
                    sizeClasses="8"
                    backdrop="static"
                    modalTitle="Preview"
                    scrollable={false}
                    handleModalToggling={handleCloseModal}
                    size="md"
                >
                    {previousImages?.length > 0 && (
                        <ImagePreview
                            images={images}
                            settings={sliderSettings}
                            containerClasses="slider-container"
                            imageContainer="image-container"
                            sliderImage="slider-img"
                        />
                    )}
                </CustomModal>
            )}
        </>
    );
};

export default ImageGallery;
