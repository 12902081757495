import DashboardIcon from "../assets/images/app/our-village-app/sidebar-icons/dashboard.svg";
import GuestUsersIcon from "../assets/images/app/our-village-app/sidebar-icons/guest-user.svg";
import ProviderIcon from "../assets/images/app/our-village-app/sidebar-icons/provider.svg";
import SeekerIcon from "../assets/images/app/our-village-app/sidebar-icons/seeker.svg";
import CategoryIcon from "../assets/images/app/our-village-app/sidebar-icons/category.svg";
import JobsIcon from "../assets/images/app/our-village-app/sidebar-icons/jobs.svg";
import SettingsIcon from "../assets/images/app/our-village-app/sidebar-icons/account.svg";

import { Roles } from "../constants";
import { APP_ROUTES } from "../helpers/routeHelpers";

let { Admin, SuperAdmin } = Roles;

const SIDEBAR_CONFIG = [
    {
        title: "Dashboard",
        url: APP_ROUTES.DASHBOARD,
        icon: null,
        menuImg: DashboardIcon,
        nestedMenu: null,
        permissions: [Admin]
    },
    // {
    //     title: "Guest Users",
    //     url: APP_ROUTES.USER_MANAGEMENT,
    //     icon: null,
    //     menuImg: GuestUsersIcon,
    //     nestedMenu: null,
    //     permissions: [Admin]
    // },
    {
        title: "Provider Management",
        url: APP_ROUTES.PROVIDER_MANAGEMENT,
        icon: null,
        menuImg: ProviderIcon,
        nestedMenu: null,
        permissions: [Admin]
    },
    {
        title: "Seeker Management",
        url: APP_ROUTES.SEEKER_MANAGEMENT,
        icon: null,
        menuImg: SeekerIcon,
        nestedMenu: null,
        permissions: [Admin]
    },
    {
        title: "Category Management",
        url: APP_ROUTES.CATEGORY_MANAGEMENT,
        icon: null,
        menuImg: CategoryIcon,
        nestedMenu: null,
        permissions: [Admin]
    },
    {
        title: "Job Listing",
        url: APP_ROUTES.JOB_LISTING,
        icon: null,
        menuImg: JobsIcon,
        nestedMenu: null,
        permissions: [Admin]
    },
    {
        title: "Account",
        url: APP_ROUTES.ACCOUNT,
        icon: null,
        menuImg: SettingsIcon,
        nestedMenu: null,
        permissions: [Admin]
    }
];

export default SIDEBAR_CONFIG;
