import React from "react";

import ChangePassword from "./ChangePassword";

const Accounts = ({}) => {
    return (
        <div className="page-content">
            <ChangePassword />
        </div>
    );
};

export default Accounts;
