import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Col, Container, Row } from "reactstrap";
import { useParams } from "react-router-dom";

import viewIcon from "../../assets/images/app/common/listing-view-image.svg";

import { getKey, MODULE_KEYS } from "../../api/api.keys";
import { GetPromotionStats } from "../../api/api.service";
import {
    formatKey,
    hasData,
    HOME_MAINTENANCE,
    renderCategoryName,
    truncateString
} from "../../utils/commonUtils";
import { useLocalPaginatedRequest } from "../../hooks/useLocalPaginatedRequest";
import { DataTable } from "../../components/DataTable";
import { monthYear } from "../../utils/dateUtils";
import { SearchBox } from "../../components/SearchBox";
import { Pagination } from "../../components/Pagination";
import { dateTimeFormat } from "../../utils/dateFormatUtils";
import { APP_ROUTES } from "../../helpers/routeHelpers";
import { ConfirmationModal, InformationModal } from "../../components/CustomModal";

const COLUMNS = {
    CATEGORY: "Category",
    SUB_CATEGORY: "Sub Categories",
    RATE_TYPE: "Rate Type",
    RATE: "Rate ($)",
    DESCRIPTION: "Description",
    AVAILIBILITY: "Availability",
    POSTED_ON: "Posted on",
    ACTION: "Action"
};
const format = dateTimeFormat.appDateFormat;
const CHARACTER_LIMIT = 30;

const DetailPromotions = () => {
    const { providerId } = useParams();
    const [modalConfig, setModalConfig] = useState(null);
    const [description, setDescription] = useState("");

    const {
        isFetching,
        isLoading,
        page,
        total,
        data,
        limit,
        searchText,
        handlePageClick,
        handleSearchChange,
        handleSortingChange
    } = useLocalPaginatedRequest({
        key: getKey([MODULE_KEYS.PROVIDER_PROMOTION_DETAIL]),
        requestFn: GetPromotionStats,
        dataKey: "data",
        totalKey: "total",
        params: {
            userId: providerId
        }
    });

    const handleShowDescription = (description) => {
        setDescription(description);
        setModalConfig(true);
    };

    const handleConfirmDescription = () => setModalConfig(false);

    return (
        <div className="mt-4">
            <Helmet>
                <title>Provider Management</title>
            </Helmet>
            <Container fluid>
                {data?.length > 0 && (
                    <Row>
                        <Col sm="12" md="3" lg="3">
                            <SearchBox
                                className="me-3 w-100 my-1"
                                value={searchText}
                                onChange={handleSearchChange}
                            />
                        </Col>
                        <Col sm="12" md="6" lg="6"></Col>
                        <Col sm="12" md="3" lg="3"></Col>
                    </Row>
                )}

                <Row>
                    <Col>
                        <DataTable
                            tableContainerClass="mt-3"
                            loading={isFetching}
                            data={data}
                            config={[
                                {
                                    title: COLUMNS.CATEGORY,
                                    render: (data) => (
                                        <div>
                                            {formatKey(
                                                data?.promotionCategory?.category?.name ==
                                                    HOME_MAINTENANCE
                                                    ? renderCategoryName(
                                                          data?.promotionCategory?.category?.name
                                                      )
                                                    : data?.promotionCategory?.category?.name
                                            ) || "-"}
                                        </div>
                                    )
                                },
                                {
                                    title: COLUMNS.SUB_CATEGORY,
                                    render: (data) => (
                                        <div>
                                            {formatKey(
                                                data?.promotionCategory?.promotionCategorySubType
                                                    ?.map(
                                                        (category) =>
                                                            category?.categorySubType?.name
                                                    )
                                                    .join(", ")
                                            ) || "-"}
                                        </div>
                                    )
                                },
                                {
                                    title: COLUMNS.RATE_TYPE,
                                    render: (data) => (
                                        <div>{truncateString(data?.rateType, 30) || "-"}</div>
                                    )
                                },
                                {
                                    title: COLUMNS.RATE,
                                    render: (data) => <div>${data?.rate || "-"}</div>
                                },
                                {
                                    title: COLUMNS.DESCRIPTION,
                                    render: (data) => {
                                        return (
                                            <div>
                                                {truncateString(
                                                    data?.description,
                                                    CHARACTER_LIMIT
                                                ) || "-"}
                                            </div>
                                        );
                                    }
                                },
                                {
                                    title: COLUMNS.AVAILIBILITY,
                                    render: (data) => (
                                        <div>
                                            {monthYear(format, data?.startDate)} {" - "}
                                            {monthYear(format, data?.endDate) || "-"}
                                        </div>
                                    )
                                },
                                {
                                    title: COLUMNS.POSTED_ON,
                                    render: (data) => (
                                        <div>
                                            {monthYear(format, data?.promotionCategory?.createdAt)}
                                        </div>
                                    )
                                },
                                {
                                    title: COLUMNS.ACTION,
                                    render: (data) => {
                                        return (
                                            <div className="d-flex">
                                                <img
                                                    style={{
                                                        filter: "brightness(0) saturate(100%) invert(58%) sepia(21%) saturate(708%) hue-rotate(283deg) brightness(83%) contrast(82%)"
                                                    }}
                                                    className="me-3"
                                                    src={viewIcon}
                                                    alt={"details icon"}
                                                    role="button"
                                                    onClick={() =>
                                                        handleShowDescription(data?.description)
                                                    } // Pass description here
                                                />
                                            </div>
                                        );
                                    }
                                }
                            ]}
                        />
                    </Col>
                </Row>
                <Pagination
                    rowLimit={limit}
                    onPageClick={handlePageClick}
                    currentPage={page}
                    totalData={total}
                    handleChange={(e) => handleSortingChange(e.target.value)}
                />
            </Container>

            <InformationModal
                headerClass="title"
                isOpen={modalConfig}
                Title="Description"
                description={description}
                confirmButtonText="Done"
                onConfirmClick={handleConfirmDescription}
            />
        </div>
    );
};

export default DetailPromotions;
