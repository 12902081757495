// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.col-width {
  width: 150px; /* Adjust this value as needed */
}

.table-fixed {
  table-layout: fixed;
  width: 100%;
}

.table-fixed th,
.table-fixed td {
  width: 33.33%; /* Adjust this percentage based on the number of columns */
  text-align: left; /* Center align text for better appearance */
}

.table-fixed th {
  white-space: nowrap; /* Prevent header text from wrapping */
}`, "",{"version":3,"sources":["webpack://./src/pages/SeekerManagement/Style.scss"],"names":[],"mappings":"AAAA;EACI,YAAA,EAAA,gCAAA;AACJ;;AAEA;EACI,mBAAA;EACA,WAAA;AACJ;;AAEA;;EAEI,aAAA,EAAA,0DAAA;EACA,gBAAA,EAAA,4CAAA;AACJ;;AAEA;EACI,mBAAA,EAAA,sCAAA;AACJ","sourcesContent":[".col-width {\n    width: 150px; /* Adjust this value as needed */\n}\n\n.table-fixed {\n    table-layout: fixed;\n    width: 100%;\n}\n\n.table-fixed th,\n.table-fixed td {\n    width: 33.33%; /* Adjust this percentage based on the number of columns */\n    text-align: left; /* Center align text for better appearance */\n}\n\n.table-fixed th {\n    white-space: nowrap; /* Prevent header text from wrapping */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
