import PropTypes from "prop-types"
import React from "react"

const Checkbox = props => {
  const {
    id,
    title,
    checked,
    onChange,
    withMargin = true,
    classes = "",
  } = props
  return (
    <div
      className={`custom-control custom-checkbox ${
        withMargin ? "mb-3" : ""
      } ${classes}`}
    >
      <input
        type="checkbox"
        className="custom-control-input"
        id={id}
        onChange={onChange}
        checked={checked}
      />
      <label className="custom-control-label" htmlFor={id}>
        {title}
      </label>
    </div>
  )
}

Checkbox.propTypes = {
  id: PropTypes.any,
  title: PropTypes.any,
  checked: PropTypes.any,
  onChange: PropTypes.any,
  withMargin: PropTypes.any,
  classes: PropTypes.any,
}

export default Checkbox
