export const downloadCSVData = (csvText) => {
    console.log("object", csvText);
    // Create a Blob from the CSV text data
    const blob = new Blob([csvText], { type: "text/csv" });

    // Generate a temporary object URL to trigger download
    const url = window.URL.createObjectURL(blob);

    // Create an anchor element to simulate download
    const a = document.createElement("a");
    a.href = url;
    a.download = `data_${new Date().getTime()}.csv`; // Set the file name
    document.body.appendChild(a);
    a.click(); // Programmatically trigger a click to download the file
    a.remove(); // Clean up by removing the anchor element

    // Release the object URL to free up memory
    window.URL.revokeObjectURL(url);
};

export const downloadXlsData = async (blob) => {
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = `template_${new Date().getTime()}.csv`; // Change the extension to .csv
    document.body.appendChild(a);
    a.click();
    a.remove();

    // Cleanup the URL object
    window.URL.revokeObjectURL(url);
};
