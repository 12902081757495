import React from "react";
import { useParams, useHistory } from "react-router-dom";

import JobDetailHeader from "./JobDetailHeader";
import JobDetails from "./JobDetails";
import { GetJobById } from "../../api/api.service";
import { getKey, MODULE_KEYS } from "../../api/api.keys";
import { hasData } from "../../utils/commonUtils";
import { LoaderFallbackUI } from "../../components/LoaderFallbackUI";
import { useRequestProcessor } from "../../hooks/useRequestProcessors";

const Details = () => {
    const { jobId } = useParams();
    const { useFetch } = useRequestProcessor();

    const { data, isLoading, isFetching, isSuccess } = useFetch(
        getKey([MODULE_KEYS.JOB_MANAGEMENT_DETAIL], { jobId }),
        async ({ signal }) => await GetJobById({ Id: jobId, params: {}, signal })
    );

    if (isFetching || isLoading || !hasData(data)) {
        return (
            <div style={{ height: "70vh" }}>
                <LoaderFallbackUI isLoader={isFetching} className={"h-100"} />
            </div>
        );
    }

    return (
        <div className="page-content">
            <JobDetailHeader status={data?.status} />
            <JobDetails data={data} isSuccess={isSuccess} />
        </div>
    );
};

export default Details;
