import React from "react";

import CustomModal from "./CustomModal";
import { CustomButton } from "../CustomButton";

const InformationModal = ({
    isOpen = false,
    Title = "",
    headerClass,
    confirmButtonText = "",
    description = "",
    onConfirmClick = () => {},
    ...rest
}) => {
    return (
        <div>
            <CustomModal
                isOpen={isOpen}
                modalTitle={Title}
                headerClasses="justify-content-center "
                {...rest}
            >
                <div className="d-flex flex-column align-items-center pt-0 pb-0 px-2">
                    {description && (
                        <div className="text-center mb-4 font-clr-theme-secondary ">
                            <p>{description}</p>
                        </div>
                    )}
                    <div className="d-flex justify-content-evenly w-75">
                        <CustomButton
                            title={confirmButtonText}
                            color="primary"
                            className="custom-btn-lg shadow-theme fw-bold"
                            onClick={onConfirmClick}
                        />
                    </div>
                </div>
            </CustomModal>
        </div>
    );
};

export default InformationModal;
