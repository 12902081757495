import React from "react";

const RadioButton = ({
    id,
    value,
    name = "",
    text = "",
    isChecked,
    onChange = undefined,
    containerClasses = ""
}) => {
    return (
        <div className={`form-check mb-3 ${containerClasses}`}>
            <input
                className="form-check-input"
                type="radio"
                name={name}
                id={id}
                value={value}
                checked={isChecked}
                onChange={onChange}
            />
            <label className="form-check-label" htmlFor={id}>
                {text}
            </label>
        </div>
    );
};

export default RadioButton;
