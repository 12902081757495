import { injectBaseConstantMethods } from "./BaseConstants";

export const CARE_TYPE = {
    CHILD_CARE: "ChildCare",
    SENIOR_CARE: "SeniorCare",
    PET_CARE: "PetCare"
};

const displayTextKeys = {
    [CARE_TYPE.CHILD_CARE]: "ChildCare",
    [CARE_TYPE.SENIOR_CARE]: "SeniorCare",
    [CARE_TYPE.PET_CARE]: "PetCare"
};

const labelClass = {
    [CARE_TYPE.CHILD_CARE]: "",
    [CARE_TYPE.SENIOR_CARE]: "",
    [CARE_TYPE.PET_CARE]: ""
};

export default injectBaseConstantMethods(CARE_TYPE, displayTextKeys, labelClass);
