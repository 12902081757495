import PropTypes from "prop-types"
import React, { useState } from "react"
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap"

import { CustomButton } from "../../components/CustomButton"
import Checkbox from "../Input/Checkbox"

const TableColumnFilter = props => {
  const { classes = "", columnData, onFilterChange } = props
  const [open, setOpen] = useState(false)

  const handleMenuToggle = e => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleItemSelect = (e, item) => {
    onFilterChange({ ...item, isShown: e.target.checked })
  }

  return (
    <div className={classes}>
      <Dropdown isOpen={open} toggle={handleMenuToggle} >
        <DropdownToggle tag="div">
          <i className="mdi mdi-menu column-filter-menu"></i>
        </DropdownToggle>
        <DropdownMenu right >
          <div className="px-2">
            {Object.values(columnData).map((item, index) => {
              return (
                <Checkbox
                  classes="column-filter-item"
                  key={index}
                  id={item.title}
                  checked={item.isShown}
                  onChange={e => handleItemSelect(e, item)}
                  withMargin={false}
                  title={item.title}
                />
              )
            })}
          </div>
        </DropdownMenu>
      </Dropdown>
    </div>
  )
}

TableColumnFilter.propTypes = {
  classes: PropTypes.any,
  columnData: PropTypes.any,
  onFilterChange: PropTypes.any,
}

export default TableColumnFilter
