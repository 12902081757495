import { injectBaseConstantMethods } from "./BaseConstants";

const CATEGORY_STATUS = {
    ACTIVE: "ACTIVE",
    INACTIVE: "INACTIVE"
};

const displayTextKeys = {
    [CATEGORY_STATUS.ACTIVE]: "Active",
    [CATEGORY_STATUS.INACTIVE]: "InActive"
};

const labelClass = {
    [CATEGORY_STATUS.ACTIVE]: "cs-badge-active",
    [CATEGORY_STATUS.INACTIVE]: "cs-badge-inactive"
};

export default injectBaseConstantMethods(CATEGORY_STATUS, displayTextKeys, labelClass);
